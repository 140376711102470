import { api } from '@~network/api';
import { ECryptoTicker, ECurrency, ETransactionStatus, ETransactionType } from '@~types/enums';
import type {
  IAccount,
  IDepositOption,
  IGatInvestPlan,
  IInvestPlan,
  IProfile,
  IProfileUpdateProps,
  IRankInfo,
  IRankRule,
  IReferralInfo,
  IReferralRule,
  IReferralSubLine,
  IToken,
  ITransaction,
  IUsdInvestPlan,
  IWallet,
  ILoginResponse,
  IPaged,
  IStatusResponse,
  TTemporaryAny,
} from '@~types/types';
import type {
  IBuyGatData,
  IChangePasswordData,
  IChangePasswordWithToken,
  ICreateDepositOptionData,
  IInvestData,
  ILoginData,
  IPromoTurkeyData,
  IReferralSubLineData,
  IRegisterData,
  IResetPasswordTokenData,
  ITransactionsData,
  IWithdrawData,
} from '@~types/requests';

export function register(username: string, email: string, password: string, uplinerRefId: string) {
  return api<IRegisterData, TTemporaryAny>({
    method: 'post',
    path: '/signup',
    data: { username, email, password, uplinerRefId },
  });
}

export function login(loginField: string, password: string) {
  return api<ILoginData, IStatusResponse<ILoginResponse>>({
    method: 'post',
    path: '/login',
    data: { username: loginField, password },
  });
}

export function resetPasswordToken(email: string) {
  return api<IResetPasswordTokenData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/forget-password',
    data: { email },
  });
}

export function changePasswordWithToken(token: string, password: string) {
  return api<IChangePasswordWithToken, IStatusResponse<null>>({
    method: 'post',
    path: '/auth/new-password',
    data: { token, password },
  });
}

export function getWallets() {
  return api<null, IWallet[]>({ method: 'get', path: '/balance' });
}

export function getTransactions(page: number, size = 10, types?: ETransactionType[], statuses?: ETransactionStatus[]) {
  const data: ITransactionsData = { page, size };
  if (types && types.length > 0) {
    data.type = types[0];
  }
  if (statuses && statuses.length > 0) {
    data.status = statuses[0];
  }

  return api<ITransactionsData, IPaged<ITransaction>>({
    method: 'get',
    path: '/history',
    data,
  });
}

export function getDepositOptions() {
  return api<null, IDepositOption[]>({ method: 'get', path: '/wallet' });
}

export function createDepositOption(crypto: ECryptoTicker) {
  return api<ICreateDepositOptionData, IStatusResponse<IDepositOption>>({
    method: 'post',
    path: '/deposit/usd/generate',
    data: { crypto },
  });
}

export function getInvestPlan() {
  return api<null, IInvestPlan>({ method: 'get', path: '/invest/plan' });
}

export function invest(stake_amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/stake',
    data: { currency, stake_amount },
  });
}

export function getGatInvestPlans() {
  return api<null, IGatInvestPlan[]>({ method: 'get', path: '/gat-invest/plans' });
}

export function investGat(stake_amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/gat-invest',
    data: { currency, stake_amount },
  });
}

export function getUsdInvestPlan() {
  return api<null, IUsdInvestPlan[]>({ method: 'get', path: '/stake_all' });
}

export function investUsd(stake_amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/stake',
    data: { currency, stake_amount },
  });
}

export function exitUsd() {
  return api<null, IStatusResponse<TTemporaryAny>>({ method: 'post', path: '/usd-invest/emergency-exit' });
}

export function buyGat(currencyAmount: number, currency = ECurrency.USD) {
  return api<IBuyGatData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/buy/gat',
    data: { currency, currencyAmount },
  });
}

export function getAccountInfo() {
  return api<null, IAccount>({ method: 'get', path: '/account' });
}

export function getProfileInfo() {
  return api<null, IProfile>({ method: 'get', path: '/profile' });
}

export function updateProfileInfo(data: IProfileUpdateProps) {
  return api<IProfileUpdateProps, TTemporaryAny>({ method: 'post', path: '/profile/name', data });
}

// export function disconnectFromTelegram() {
//   return api<null, IStatusResponse<IProfile>>({ method: 'delete', path: '/profile/tg-token' });
// }

// export function getTgToken() {
//   return api<null, IStatusResponse<IToken>>({ method: 'get', path: '/profile/tg-token' });
// }

// export function generateTgToken() {
//   return api<null, IStatusResponse<IToken>>({ method: 'post', path: '/profile/tg-token' });
// }

export function changePassword(password: string, newpassword: string) {
  return api<IChangePasswordData, TTemporaryAny>({
    method: 'post',
    path: '/profile/uppassword',
    data: { password, newpassword },
  });
}

// export function getRankInfo() {
//   return api<null, IStatusResponse<IRankInfo>>({ method: 'get', path: '/rank' });
// }

// export function getRankRules() {
//   return api<null, IStatusResponse<IRankRule[]>>({ method: 'get', path: '/rank/rules' });
// }

export function getReferralInfo() {
  return api<null, IReferralInfo>({ method: 'get', path: '/referal' });
}

export function getReferralRules() {
  return api<null, IReferralRule[]>({ method: 'get', path: '/referal/rules' });
}

export function getReferralSubLine(username: string) {
  return api<IReferralSubLineData, IStatusResponse<IReferralSubLine[]>>({
    method: 'get',
    path: `/referal/partners`,
    // path: `/referal/partners/${username}`,
    data: { username },
  });
}

// export function getPromoTurkey() {
//   return api<null, IStatusResponse<IPromoTurkeyData>>({ method: 'get', path: '/promo/turkey' });
// }

// export function applyForPromoTurkey() {
//   return api<null, IStatusResponse<IPromoTurkeyData>>({ method: 'post', path: '/promo/turkey' });
// }

export function withdraw(amount: number, address: string) {
  return api<IWithdrawData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/withdraw',
    data: { amount, address },
  });
}
