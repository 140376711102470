import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';

import { selectedLanguageAtom } from '@~store/app.store';
import { ELanguage } from '@~types/enums';
import { ILanguageMeta } from '@~types/types';

import { LanguageSwitcherButton } from './elements/LanguageSwitcherButton';

export interface ILanguageSwitcherProps {
  className?: string;
  onClick?: () => void;
}

function createLandObject(id: ELanguage, name: string, flag?: string) {
  return {
    id,
    lang: id,
    flag: flag || id,
    name,
  };
}

const languageMetaMap: Record<ELanguage, ILanguageMeta> = {
  [ELanguage.RU]: createLandObject(ELanguage.RU, 'Русский'),
  [ELanguage.EN]: createLandObject(ELanguage.EN, 'English', 'us'),
  [ELanguage.CN]: createLandObject(ELanguage.CN, '中国人'),
  [ELanguage.ES]: createLandObject(ELanguage.ES, 'Español'),
  [ELanguage.FR]: createLandObject(ELanguage.FR, 'Français'),
  [ELanguage.HI]: createLandObject(ELanguage.HI, 'हिंदी', 'in'),
  [ELanguage.AR]: createLandObject(ELanguage.AR, 'العربية'),
  [ELanguage.PT]: createLandObject(ELanguage.PT, 'Português'),
  [ELanguage.DE]: createLandObject(ELanguage.DE, 'Deutsch'),
  [ELanguage.IT]: createLandObject(ELanguage.IT, 'Italiano'),
  [ELanguage.TR]: createLandObject(ELanguage.TR, 'Türk'),
};

export const LanguageSwitcher = ({ className, onClick }: ILanguageSwitcherProps) => {
  const [selectedLanguage] = useAtom(selectedLanguageAtom);

  const selectedLanguageMeta = useMemo(() => {
    return languageMetaMap[selectedLanguage];
  }, [selectedLanguage]);

  return (
    <div id="language-switcher" className={classNames('d-flex justify-content-center', className)}>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-language"
          key="dropdown-language"
          variant="transparent"
          className="notranslate pe-0 pe-md-2 pw-2"
        >
          <FontAwesomeIcon icon={faGlobe} className="me-2" />
          {selectedLanguageMeta?.lang?.toUpperCase()}
          <FontAwesomeIcon icon={faAngleDown} className="caret" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="languages-list">
          {Object.entries(ELanguage).map(([key, lang]) => (
            <LanguageSwitcherButton languageMeta={languageMetaMap[lang]} key={key} onClick={onClick} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
