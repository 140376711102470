import { api } from '@~network/api';
import type { ITokenBank } from '@~types/types';

const GOLD_API_SECRET = 'goldapi-1bregqzrlhqnv5g8-io';

export function getTokenBank() {
  return api<null, ITokenBank>({ method: 'get', path: '/token-bank' });
}

export function getGoldPrice() {
  const headers = new Headers();
  headers.append('x-access-token', GOLD_API_SECRET);
  headers.append('Content-Type', 'application/json');

  const errorMessage = 'Error fetch gold currency';

  return fetch('https://www.goldapi.io/api/XAU/USD', {
    headers: headers,
    redirect: 'follow',
    method: 'GET',
  })
    .then(async (response) => {
      if (response.status !== 200) {
        throw new Error(errorMessage);
      } else {
        const json = await response.json();
        return json.price_gram_24k;
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
    });
}
