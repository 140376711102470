import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { nameSurnameRx, usernameRx } from '@~constants';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { updateProfileInfo } from '@~network/account';
import { profileInfoAtom } from '@~store/account.store';
import type { IProfile } from '@~types/types';

interface IEditProfileModalProps {
  profileInfo: IProfile;
}

export const EditProfileModal = memo(({ profileInfo }: IEditProfileModalProps) => {
  const { t } = useTranslation();

  const [, setProfileInfo] = useAtom(profileInfoAtom);

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();

  const isValidProfileInput = useCallback(() => {
    if (firstname) {
      if (firstname.length < 2 || firstname.length > 50) {
        handleResponse(t('error.name-length') + '', true);
        return false;
      }

      if ((firstname.match(/[- ]/g) || []).length > 2) {
        handleResponse(t('error.wrong-name') + '', true);
        return false;
      }

      if (!nameSurnameRx.test(firstname)) {
        handleResponse(t('error.wrong-name') + '', true);
        return false;
      }
    }

    if (lastname) {
      if (lastname.length < 2 || lastname.length > 50) {
        handleResponse(t('error.surname-length') + '', true);
        return false;
      }

      if ((lastname.match(/[- ]/g) || []).length > 2) {
        handleResponse(t('error.wrong-name') + '', true);
        return false;
      }

      if (!nameSurnameRx.test(lastname)) {
        handleResponse(t('error.wrong-surname') + '', true);
        return false;
      }
    }

    if (username.length < 4 || username.length > 12) {
      handleResponse(t('error.username-length') + '', true);
      return false;
    }

    if (!usernameRx.test(username)) {
      handleResponse(t('error.wrong-username') + '', true);
      return false;
    }

    return true;
  }, [username, firstname, lastname, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    if (!isValidProfileInput()) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    updateProfileInfo({ username, first_name: firstname, last_name: lastname, ownRefId: profileInfo.ownRefId })
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.profile.profile-update-success') + '', false);
          setProfileInfo(response.data);
          setOpenModal(false);
        } else if (response.warningMessage === "Пользователь с таким ником уже существует") {
          handleResponse(t('error.wrong-username-exist') + '', true);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [isValidProfileInput, setProfileInfo, profileInfo, handleResponse, username, firstname, lastname, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  useEffect(() => {
    setUsername(profileInfo.username);
    setFirstname(profileInfo.first_name || '');
    setLastname(profileInfo.last_name || '');
  }, [openModal, profileInfo]);

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </button>

      <ModalAlert
        key="modal-for-edit-profile"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.profile.content.profile.modal-title') + ''}
        okLabel={t('common.action.save') + ''}
        loading={isLoading}
        enableEscape
        content={
          <div className="mb-3">
            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.profile.username-label')}</div>
              <input
                type="text"
                className="form-control form-control-lg mb-2"
                placeholder={t('pages.account.profile.content.profile.username-placeholder') + ''}
                value={username}
                id="username"
                onChange={(event) => setUsername(event.target.value)}
                disabled={isLoading}
              />
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.profile.name-label')}</div>
              <input
                type="text"
                className="form-control form-control-lg mb-2"
                placeholder={t('pages.account.profile.content.profile.name-placeholder') + ''}
                value={firstname}
                id="firstname"
                onChange={(event) => setFirstname(event.target.value)}
                disabled={isLoading}
              />
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.profile.surname-label')}</div>
              <input
                type="text"
                className="form-control form-control-lg mb-2"
                placeholder={t('pages.account.profile.content.profile.surname-placeholder') + ''}
                value={lastname}
                id="lastname"
                onChange={(event) => setLastname(event.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
        }
      />
    </>
  );
});
