import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Table } from '@~components/table/Table';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getReferralSubLine } from '@~network/account';
import { emptySubLinesHiddenAtom, profileInfoAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import type { IHeader, IReferralSubLine } from '@~types/types';

const tableHeaders: IHeader[] = [
  { key: 'line', translateKey: 'pages.account.partners.content.affiliate-program.line' },
  { key: 'username', translateKey: 'pages.account.partners.content.affiliate-program.sublines.username' },
  // { key: 'email', translateKey: 'pages.account.partners.content.affiliate-program.sublines.email' },
  // {
  //   key: 'personal-turnover',
  //   translateKey: 'pages.account.partners.content.affiliate-program.sublines.personal-turnover',
  // },
  // { key: 'branch-turnover', translateKey: 'pages.account.partners.content.affiliate-program.sublines.branch-turnover' },
  { key: 'whole-turnover', translateKey: 'pages.account.partners.content.affiliate-program.sublines.whole-turnover' },
];

const ROWS_PER_PAGE = 10;

export const PartnersStructureModal = memo(() => {
  const { t } = useTranslation();

  const [profileInfo] = useAtom(profileInfoAtom);
  const [isEmptySubLinesHidden, setEmptySubLinesHidden] = useAtom(emptySubLinesHiddenAtom);

  const [currentUsername, setCurrentUsername] = useState('');
  const [usersLine, setUsersLine] = useState<string[]>([]);
  const [userLines, setUserLines] = useState<Record<string, IReferralSubLine[]>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);

  const handleResponse = useResponseHandler();

  const openLineByUsername = useCallback(
    (username: string) => {
      setIsLoading(true);

      getReferralSubLine(username)
        .then((response) => {
          if (response.success && response.data) {
            setUserLines((prevState) => ({
              ...prevState,
              [username]: response.data || [],
            }));
          } else {
            handleResponse(response);
          }
        })
        .catch((response) => {
          handleResponse(response.response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleResponse]
  );

  const handleEmptyPartnersHidden = useCallback(() => {
    setEmptySubLinesHidden(!isEmptySubLinesHidden);
  }, [isEmptySubLinesHidden, setEmptySubLinesHidden]);

  useEffect(() => {
    if (profileInfo) {
      setCurrentUsername(profileInfo.username);
      setUsersLine([profileInfo.username]);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (currentUsername) {
      openLineByUsername(currentUsername);
      setPage(0);
    }
  }, [currentUsername, openLineByUsername]);

  useEffect(() => {
    setPage(0);
  }, [isEmptySubLinesHidden]);

  const tableRows = useMemo(() => {
    const userReferrals = userLines[currentUsername] || [];
    if (userReferrals.length === 0) {
      return [];
    }
    let filteredReferrals = userReferrals;
    if (isEmptySubLinesHidden) {
      filteredReferrals = filteredReferrals.filter((referral) => referral.personalTurnover !== 0);
    }

    return filteredReferrals.map((referral) => ({
      id: referral.username,
      cells: [
        referral.line,
        <button
          key="select-referral"
          className="btn btn-link tx-left px-0 py-0"
          onClick={() => {
            setCurrentUsername(referral.username);
            setUsersLine((prevState) => [...prevState, referral.username]);
          }}
        >
          {referral.username}
        </button>,
        // referral.email,
        // <FormattedNumber
        //   key="personal-turnover"
        //   value={referral.personalTurnover}
        //   postfix={ECurrency.USDT}
        //   decimals={2}
        //   floor
        // />,
        // <FormattedNumber
        //   key="branch-turnover"
        //   value={referral.branchTurnover}
        //   postfix={ECurrency.USDT}
        //   decimals={2}
        //   floor
        // />,
        <FormattedNumber
          key="whole-turnover"
          value={referral.total_invest}
          postfix={ECurrency.USDT}
          decimals={2}
          floor
        />,
      ],
    }));
  }, [currentUsername, userLines, isEmptySubLinesHidden]);

  const handlePageChange = useCallback((pageNr: number) => {
    setPage(pageNr);
  }, []);

  return (
    <>
      <button className="btn btn-link p-0" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon
          icon={faUsers}
          title={t('pages.account.partners.content.affiliate-program.sublines.title') + ''}
        />
      </button>

      <ModalAlert
        key="modal-for-affiliate-program"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.partners.content.affiliate-program.sublines.title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        size="xl"
        centered={false}
        noOk
        enableEscape
        content={
          <div className="mb-3">
            <div className="mb-2">
              <label className="d-flex gap-3">
                <input type="checkbox" defaultChecked={isEmptySubLinesHidden} onClick={handleEmptyPartnersHidden} />{' '}
                {t('pages.account.partners.content.affiliate-program.sublines.hide-partners-with-empty-deposit')}
              </label>
            </div>

            <div className="mb-2">
              {usersLine.map((username, index) => {
                const isLast = index === usersLine.length - 1;
                return (
                  <div key={`users-line-${username}`} className="d-inline">
                    {index !== 0 && <FontAwesomeIcon icon={faChevronRight} className="mx-2 tx-14" />}
                    {!isLast && (
                      <button
                        className="btn btn-link tx-primary text-decoration-none tx-left px-0 py-0 pb-1"
                        onClick={() => {
                          setCurrentUsername(username);
                          setUsersLine((prevState) => prevState.slice(0, index + 1));
                        }}
                      >
                        {username}
                      </button>
                    )}
                    {isLast && <span className="tx-white tx-bold">{username}</span>}
                  </div>
                );
              })}
            </div>

            <Table
              id="user-first-line-table"
              headers={tableHeaders}
              rows={tableRows.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)}
              total={tableRows.length}
              isLoading={isLoading}
              dataLoaded={Object.keys(userLines).length > 0}
              initialPage={page}
              rowsPerPage={ROWS_PER_PAGE}
              handlePageChange={handlePageChange}
            />
          </div>
        }
      />
    </>
  );
});
