import { faPen } from '@fortawesome/pro-duotone-svg-icons/faPen';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { changePassword } from '@~network/account';

export const ChangePasswordModal = memo(() => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();

  const isValidPasswordsInput = useCallback(() => {
    if (confirmPassword !== newPassword) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    if (!newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
      handleResponse(t('error.password-Aa0') + '', true);
      return;
    }

    return true;
  }, [password, newPassword, confirmPassword, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    if (!isValidPasswordsInput()) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    changePassword(password, newPassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.security.password-changed') + '', false);
          setOpenModal(false);
          setPassword('')
          setNewPassword('')
          setConfirmPassword('')
          setShowPassword(false)
          setShowNewPassword(false)

        } else if (response.warningMessage === "Старый и новый пароль должны быть разными") {
          handleResponse(t('error.new-old-password') + '', true);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [isValidPasswordsInput, handleResponse, password, newPassword, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        setPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setShowPassword(false)
        setShowNewPassword(false)
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPen} />
      </button>

      <ModalAlert
        key="modal-for-change-password"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.profile.content.security.modal-title') + ''}
        okLabel={t('pages.account.profile.content.security.change-password') + ''}
        enableEscape
        content={
          <div className="mb-3">
            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.security.current-password')}</div>
              <div className="input-group">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.current-password') + ''}
                  value={password}
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.security.new-password')}</div>
              <div className="input-group">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.new-password') + ''}
                  value={newPassword}
                  id="new-password"
                  onChange={(event) => setNewPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowNewPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.security.confirm-password')}</div>
              <div className="input-group">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.confirm-password') + ''}
                  value={confirmPassword}
                  id="confirm-new-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowNewPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
});
