import { useAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isAdminAtom } from '@~store/account.store';

export const AdminApplier = memo(() => {
  const [, setAdmin] = useAtom(isAdminAtom);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#amahustler') {
      setAdmin(true);
    }
  }, [setAdmin, location]);

  return null;
});
