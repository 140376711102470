import { faRightToBracket } from '@fortawesome/pro-duotone-svg-icons/faRightToBracket';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { Translate } from '@~components/translate/Translate';
import { emailRx, usernameRx } from '@~constants';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { register } from '@~network/account';
import { loggedAtom, refIdAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

export const RegisterPage = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoggedIn] = useAtom(loggedAtom);
  const [refId] = useAtom(refIdAtom);

  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [partnerID, setPartnerID] = useState(refId || '');

  const handleResponse = useResponseHandler();

  const isValidInput = useCallback(() => {
    if (username.length < 4 || username.length > 12) {
      handleResponse(t('error.username-length') + '', true);
      return false;
    }

    if (!usernameRx.test(username)) {
      handleResponse(t('error.wrong-username') + '', true);
      return false;
    }

    if (email.length < 6 || !emailRx.test(email)) {
      handleResponse(t('error.wrong-email') + '', true);
      return false;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return false;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      handleResponse(t('error.wrong-password') + '', true);
      return false;
    }

    if (passwordRepeat !== password) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return false;
    }

    if (!partnerID) {
      handleResponse(t('error.no-referral') + '', true);
      return false;
    }

    return true;
  }, [partnerID, username, email, password, passwordRepeat, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isValidInput()) {
      return;
    }

    setLoading(true);
    register(username, email, password, partnerID)
      .then((response) => {
        if (response.success) {
          handleResponse(t('register.success') + '', false);
          setTimeout(() => {
            navigate(EAccountLink.LOGIN);
          }, 5000);
        } else if (response.warningMessage === "Неверный ID наставника") {
          handleResponse(t('error.wrong-referral') + '', true);
        } else if (response.warningMessage === "Пользователь с таким ником уже существует") {
          handleResponse(t('error.wrong-username-exist') + '', true);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [navigate, username, email, password, partnerID, isValidInput, handleResponse, t]);

  if (isLoggedIn) {
    return <Navigate replace to={EAccountLink.ROOT} />;
  }

  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous | {t('pages.register.title')}</title>
      </Helmet>
      <div id="register" className="page flex-1 p-4 p-md-5 pt-navbar">
        <div className="container">
          <div id="login-form" className="centered-block">
            <h1 className="mb-4 text-center">
              <Translate i18nKey="pages.register.content.heading" />
            </h1>

            <form
              onSubmit={(event) => {
                event.preventDefault();
                submitForm();
              }}
              className="card mb-3"
            >
              <h2 className="tx-uppercase ms-3 mt-3 mb-5">{t('pages.register.title')}</h2>

              <div className="form-group mb-3">
                <div className="ms-3 tx-18 mb-1">{t('pages.register.content.username-label')}</div>
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  placeholder={t('pages.register.content.username-placeholder') + ''}
                  value={username}
                  id="username"
                  onChange={(event) => setUsername(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group mb-3">
                <div className="ms-3 tx-18 mb-1">{t('pages.register.content.email-label')}</div>
                <input
                  type="email"
                  className="form-control form-control-lg mb-2"
                  placeholder={t('pages.register.content.email-placeholder') + ''}
                  value={email}
                  id="email"
                  onChange={(event) => setEmail(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group mb-3">
                <div className="ms-3 tx-18 mb-1">{t('pages.register.content.password-label')}</div>
                <div className="input-group">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    placeholder={t('pages.register.content.password-placeholder') + ''}
                    value={password}
                    id="password"
                    onChange={(event) => setPassword(event.target.value)}
                    disabled={isLoading}
                  />
                  <div className="input-group-text">
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      role="button"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <div className="ms-3 tx-18 mb-1">{t('pages.register.content.password-repeat-label')}</div>
                <div className="input-group">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    placeholder={t('pages.register.content.password-repeat-placeholder') + ''}
                    value={passwordRepeat}
                    id="password-repeat"
                    onChange={(event) => setPasswordRepeat(event.target.value)}
                    disabled={isLoading}
                  />
                  <div className="input-group-text">
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      role="button"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mb-6">
                <div className="ms-3 tx-18 mb-1">{t('pages.register.content.partner-id-label')}</div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder={t('pages.register.content.partner-id-placeholder') + ''}
                  value={partnerID}
                  id="partner-id"
                  onChange={(event) => setPartnerID(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="text-center mb-4">
                <button className="btn btn-lg btn-primary px-5" type="submit" disabled={isLoading}>
                  <IconWithLoading icon={faRightToBracket} className="me-2" isLoading={isLoading} />
                  {t('pages.register.content.button-register')}
                </button>
              </div>
            </form>

            <div className="card py-3">
              <div className="text-center">
                {t('pages.register.content.have-account')}{' '}
                <Link to={EAccountLink.LOGIN}>{t('pages.register.content.login')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
