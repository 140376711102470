import { useAtom } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Placeholder } from '@~components/placeholder/Placeholder';
import { PartnersStructureModal } from '@~pages/account/partners/modals/PartnersStructureModal';
import { tokenBankAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import type { IReferralInfo } from '@~types/types';

interface IAffiliateLevelProps {
  referralInfo: IReferralInfo | undefined;
}

export const AffiliateLevel = memo(({ referralInfo }: IAffiliateLevelProps) => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);

  const totalAftEarnedInUsdt = useMemo(() => {
    if (tokenBank && referralInfo) {
      return referralInfo.total_earn;
    }
    return 0;
  }, [tokenBank, referralInfo]);

  const totalAftMissedInUsdt = useMemo(() => {
    if (tokenBank && referralInfo) {
      return referralInfo.total_lose;
    }
    return 0;
  }, [tokenBank, referralInfo]);

  return (
    <div className="card-block">
      <div className="row ht-100p">
        {/* <div className="col-12 col-xxxl-6 mb-4 d-flex d-xxxl-block justify-content-between">
          <div className="tx-uppercase tx-spacing-1">{t('pages.account.partners.content.affiliate-program.level')}</div>
          {referralInfo ? (
            <FormattedNumber value={referralInfo.refLevel} className="tx-gray-600 tx-bold" />
          ) : (
            <div className="text-end text-xxxl-start">
              <Placeholder width="50px" />
            </div>
          )}
        </div> */}
        <div className="col-12 col-xxxl-6 mb-4 d-flex d-xxxl-block justify-content-between">
          <div className="tx-uppercase tx-spacing-1 d-flex justify-content-start gap-4">
            <div>{t('pages.account.partners.content.affiliate-program.partners')}</div>
            <PartnersStructureModal />
          </div>
          {referralInfo ? (
            <FormattedNumber value={referralInfo.totalReferrals} className="tx-gray-600 tx-bold" />
          ) : (
            <div className="text-end text-xxxl-start">
              <Placeholder width="50px" />
            </div>
          )}
        </div>
        <div className="col-12 col-xxxl-6 mb-4 d-flex d-xxxl-block justify-content-between">
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.earned')}
          </div>
          {referralInfo ? (
            <div className="tx-gray-600 text-end text-xxxl-start">
              <FormattedNumber value={referralInfo.total_earn} postfix={ECurrency.USDT} className="tx-bold" />
              {totalAftEarnedInUsdt > 0 && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={totalAftEarnedInUsdt}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
          ) : (
            <div className="text-end text-xxxl-start">
              <Placeholder width="50px" />
            </div>
          )}
        </div>
        <div className="col-12 col-xxxl-6 mb-4 d-flex d-xxxl-block justify-content-between">
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.missed')}
          </div>
          {referralInfo ? (
            <div className="tx-gray-600 text-end text-xxxl-start">
              <FormattedNumber value={referralInfo.total_lose} postfix={ECurrency.USDT} className="tx-bold" />
              {totalAftMissedInUsdt > 0 && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={totalAftMissedInUsdt}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
          ) : (
            <div className="text-end text-xxxl-start">
              <Placeholder width="50px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
