import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from 'locales/ar/ar.json';
import cn from 'locales/cn/cn.json';
import de from 'locales/de/de.json';
import en from 'locales/en/en.json';
import es from 'locales/es/es.json';
import fr from 'locales/fr/fr.json';
import hi from 'locales/hi/hi.json';
import it from 'locales/it/it.json';
import pt from 'locales/pt/pt.json';
import ru from 'locales/ru/ru.json';
import tr from 'locales/tr/tr.json';

import { ELanguage } from '@~types/enums';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  cn: {
    translation: cn,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  hi: {
    translation: hi,
  },
  ar: {
    translation: ar,
  },
  pt: {
    translation: pt,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
  tr: {
    translation: tr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,

    fallbackLng: ELanguage.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

document.documentElement.lang = i18n.language;

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
