import classNames from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Translate } from '@~components/translate/Translate';

import styles from './AssetsPrice.module.scss';
import { Loading } from 'components/loading';
import { getGoldPrice } from '@~network/public';
import { DEFAULT_GOLD_PRICE } from './constants';

export const AssetsPrice = memo(() => {
  const [priceFor1G, setPriceFor1G] = useState<number | null>(null);

  useEffect(() => {
    getGoldPrice().then((result) => {
      setPriceFor1G(!result ? DEFAULT_GOLD_PRICE : result);
    });
  }, []);

  const price1000g = useMemo(() => {
    if (!priceFor1G) {
      return null;
    }
    return priceFor1G * 1000;
  }, [priceFor1G]);

  const price100g = useMemo(() => {
    if (!priceFor1G) {
      return null;
    }
    return priceFor1G * 100;
  }, [priceFor1G]);

  const price10g = useMemo(() => {
    if (!priceFor1G) {
      return null;
    }
    return priceFor1G * 10;
  }, [priceFor1G]);

  return (
    <section className={classNames(styles.root, 'container-fluid bg-carbon mt-5')}>
      <div className="container">
        <div className="row pb-1 tx-center">
          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.aft-asset-value'} />
            </span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              {!price1000g ? <Loading /> : <FormattedNumber value={price1000g} postfix={' $'} decimals={2} />}
            </span>
          </div>

          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.gat-asset-value'} />
            </span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              {!price100g ? <Loading /> : <FormattedNumber value={price100g} postfix={' $'} decimals={2} />}
            </span>
          </div>

          <div className="col-md-4 tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.gold-price'} />
            </span>
            <span className="tx-24 tx-lg-30 pt-2">
              {!price10g ? <Loading /> : <FormattedNumber value={price10g} postfix={' $'} decimals={2} />}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
});
