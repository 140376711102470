import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Table } from '@~components/table/Table';
import { ECurrency } from '@~types/enums';
import type { IHeader, IReferralRule } from '@~types/types';

const tableHeaders: IHeader[] = [
  { key: 'level', translateKey: 'pages.account.partners.content.affiliate-program.level' },
  { key: 'percents', translateKey: 'pages.account.partners.content.affiliate-program.percents' },
  // { key: 'min-deposit', translateKey: 'pages.account.partners.content.affiliate-program.min-deposit' },
  // {
  //   key: 'min-network-turnover',
  //   translateKey: 'pages.account.partners.content.affiliate-program.min-network-turnover',
  // },
];

interface IAffiliateProgramModalProps {
  referralRules: IReferralRule[];
}

export const AffiliateProgramModal = memo(({ referralRules }: IAffiliateProgramModalProps) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const tableRows = useMemo(
    () =>
      referralRules.map((rule) => ({
        id: rule.line + '',
        cells: [
          rule.line,
          <FormattedNumber key="percents" value={rule.rewardPercents} postfix="%" className="tx-bold" />,
          // <FormattedNumber
          //   key="personal-deposit"
          //   value={rule.minPersonalUsdDeposit}
          //   postfix={ECurrency.USDT}
          //   className="tx-bold"
          // />,
          // <FormattedNumber
          //   key="team-turnover"
          //   value={rule.minPersonalUsdTurnover}
          //   postfix={ECurrency.USDT}
          //   className="tx-bold"
          // />,
        ],
      })),
    [referralRules]
  );

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="tx-primary interactive"
        size="2x"
        onClick={() => setOpenModal(true)}
        title={`${t('pages.account.partners.content.affiliate-program.info-title')}`}
      />

      <ModalAlert
        key="modal-for-affiliate-program"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.partners.content.affiliate-program.info-title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        size="xl"
        noOk
        enableEscape
        content={
          <div className="mb-3">
            <Table
              id="affiliate-program-table"
              headers={tableHeaders}
              rows={tableRows}
              rowsPerPage={referralRules.length}
            />
          </div>
        }
      />
    </>
  );
});
