const {
  REACT_APP_NODE_ENV: nodeEnv = 'local',
  REACT_APP_ENV: env = 'local',
  REACT_APP_IS_TEST_ENV,
  REACT_APP_API_URL: apiUrl = '',
  REACT_APP_TG_BOT_URL: tgBotUrl = '',
} = process.env;

export const config = {
  nodeEnv,
  env,
  isTestEnv: REACT_APP_IS_TEST_ENV === 'true',
  apiUrl,
  tgBotUrl,
};
