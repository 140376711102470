export enum ELanguage {
  RU = 'ru',
  EN = 'en',
  CN = 'cn',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  AR = 'ar',
  PT = 'pt',
  DE = 'de',
  IT = 'it',
  TR = 'tr',
}

export enum EAccountRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
}

export enum EPromoStatus {
  ACTIVE = 'ACTIVE',
  APPLIED = 'APPLIED',
  FINISHED = 'FINISHED',
}

export enum ESort {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ETransactionType {
  DEPOSIT = 'deposit',
  // DEPOSIT_USDT = 'DEPOSIT_USDT',
  // BUY_GAT = 'BUY_GAT',
  GAT_INVEST_AUF = 'GAT_INVEST_AUF', // TODO: To be removed
  // GAT_INVEST_AFT = 'GAT_INVEST_AFT',
  GAT_INVEST_USD = 'GAT_INVEST_USD', // TODO: To be removed
  // GAT_INVEST_USDT = 'GAT_INVEST_USDT',
  GAT_INVEST_GAT = 'GAT_INVEST_GAT',
  USD_INVEST_USD = 'USD_INVEST_USD',
  USD_INVEST_GAT = 'USD_INVEST_GAT',
  USD_INVEST_AUF = 'USD_INVEST_AUF',
  INVEST_USD = 'INVEST_USD',
  // INVEST_USDT = 'INVEST_USDT',
  INVEST_AUF = 'INVEST_AUF', // TODO: To be removed
  // INVEST_AFT = 'INVEST_AFT',
  PASSIVE_PAYOUT = 'PASSIVE_PAYOUT',
  USD_PASSIVE_PAYOUT = 'USD_PASSIVE_PAYOUT',
  GAT_PASSIVE_PAYOUT = 'GAT_PASSIVE_PAYOUT',
  GAT_GREAT_PASSIVE_PAYOUT = 'GAT_GREAT_PASSIVE_PAYOUT',
  REFERRAL_PAYOUT = 'referral_payout',
  REFERRAL_PAYOUT_GAT = 'REFERRAL_PAYOUT_GAT',
  REFERRAL_PAYOUT_USD = 'REFERRAL_PAYOUT_USD',
  WITHDRAWAL = 'withdrawal',
  USD_INVEST_EXIT = 'USD_INVEST_EXIT',
  AUF_INVEST_EXIT = 'AUF_INVEST_EXIT',
  GAT_INVEST_EXIT = 'GAT_INVEST_EXIT',
  RANK_BONUS_PAYOUT = 'RANK_BONUS_PAYOUT',
  ADMIN_DEPOSIT_USD = 'ADMIN_DEPOSIT_USD', // TODO: To be removed
  // ADMIN_DEPOSIT_USDT = 'ADMIN_DEPOSIT_USDT',
  ADMIN_DEPOSIT_AUF = 'ADMIN_DEPOSIT_AUF', // TODO: To be removed
  // ADMIN_DEPOSIT_AFT = 'ADMIN_DEPOSIT_AFT',
  ADMIN_DEPOSIT_GAT = 'ADMIN_DEPOSIT_GAT',
  STAKE = 'stake',
  STAKE_PROFIT = 'stake_profit'
}

export enum ECurrency {
  USD = 'USD', // TODO: To be removed
  USDT = 'USDT',
  AUF = 'AUF', // TODO: To be removed
  AFT = 'AFT',
  GAT = 'GAT',
}

export enum ECryptoTicker {
  BTC = 'BTC',
  ETH = 'ETH',
  ERC_USDT = 'ERC_USDT',
  TRC_USDT = 'TRC_USDT',
}

export enum ETransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  LOCKED_BY_X_FACTOR = 'LOCKED_BY_X_FACTOR',
  MISSED = 'MISSED',
  ERROR = 'ERROR',
}

export enum EInvestPlanStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ETableView {
  TABLE = 'table',
  LIST = 'list',
}

export enum EHistoryFilter {
  ALL = 'all',
  DEPOSITS = 'deposits',
  WITHDRAWALS = 'withdrawals',
  REFERRALS = 'referrals',
  INVESTMENTS = 'investments',
  // BONUSES = 'bonuses',
  // OTHERS = 'others',
}
