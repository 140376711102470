import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';

export const AftPlanInfoModal = memo(() => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="tx-primary interactive"
        size="2x"
        onClick={() => setOpenModal(true)}
        title={`${t('common.information')}`}
      />

      <ModalAlert
        key="modal-for-aft-info"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.staking.content.aft-plan.title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        centered={false}
        size="lg"
        noOk
        enableEscape
        content={
          <div className="modal-content-bg">
            <p>{t('pages.account.staking.content.aft-plan.text.1')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.2')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.3')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.4')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.5')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.6')}</p>
            {/* <p>{t('pages.account.staking.content.aft-plan.text.7')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.8')}</p>
            <p>{t('pages.account.staking.content.aft-plan.text.9')}</p>
            <div className="mt-5">
              <div className="tx-bold tx-20">{t('common.attention')}:</div>
              <div className="tx-italic">{t('pages.account.staking.content.aft-plan.text.attention')}</div>
            </div> */}
          </div>
        }
      />
    </>
  );
});
