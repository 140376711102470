import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faEnvelope, faFileCertificate, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SocialLinks } from '@~components/footer/elements/social-links/SocialLinks';
import { Documents } from '@~components/footer/elements/documents/Documents';
import { PdfFile } from '@~components/pdf-file/PdfFile';
import { StaticLogo } from '@~components/static-logo/StaticLogo';
import { loggedAtom } from '@~store/account.store';
import { selectedLanguageAtom } from '@~store/app.store';
import { ELanguage } from '@~types/enums';

import feasibilityStudy from '@~assets/files/FeasibilityStudy.pdf';
import feasibilityStudyRu from '@~assets/files/FeasibilityStudy-ru-translated.pdf';
import aurousFinanceRu from '@~assets/files/aurous-finance-presentation-ru.pdf';
import aurousFinanceEn from '@~assets/files/aurous-finance-presentation-en.pdf';

const presentationMap: Partial<Record<ELanguage, string>> = {
  [ELanguage.RU]: aurousFinanceRu,
  [ELanguage.EN]: aurousFinanceEn,
};

const feasibilityStudyMap: Partial<Record<ELanguage, string>> = {
  [ELanguage.RU]: feasibilityStudyRu,
  [ELanguage.EN]: feasibilityStudy,
};

export const Footer = () => {
  const [isLoggedIn] = useAtom(loggedAtom);
  const [selectedLanguage] = useAtom(selectedLanguageAtom);

  const { t } = useTranslation();

  const whitePaperUri: string = useMemo(() => {
    return presentationMap[selectedLanguage] || aurousFinanceEn;
  }, [selectedLanguage]);

  const feasibilityStudyUri: string = useMemo(() => {
    return feasibilityStudyMap[selectedLanguage] || feasibilityStudy;
  }, [selectedLanguage]);

  return (
    <section id="footer" className="d-flex align-items-end flex-1 bg-black-2 pt-5 pb-3 pos-relative">
      <div className="fluid-container px-2 px-md-3 px-lg-4">
        <div className="row">
          <div className="col-md-12 col-lg-3 text-center mt-4 mt-lg-0 mb-lg-0 order-last order-lg-0">
            <StaticLogo />
            <div className="tx-14 tx-gray-700 mt-3 mt-md-5 pt-md-5">
              &copy; {new Date().getFullYear()} {t('footer.all-rights-reserved')}.
            </div>
          </div>

          <div className="col-md-3 col-lg-2 mb-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">{t('footer.files')}</div>

            <div className="mt-2">
              <PdfFile name={t('footer.white-paper') + ''} fileUri={whitePaperUri} />
            </div>

            {isLoggedIn && (
              <>
                <div className="mt-2">
                  <PdfFile name={t('footer.feasibility-study') + ''} fileUri={feasibilityStudyUri} />
                </div>
                <div className="mt-2">
                  <Documents />
                </div>
              </>
            )}
          </div>

          <div className="col-md-4 col-lg-2 mb-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">{t('footer.links')}</div>

            <SocialLinks />

            <div className="mt-2">
              <a href="https://dzen.ru/aurous_finance" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.blog')}
              </a>
            </div>

            <div className="mt-2">
              <a href="https://t.me/AUROUS_FINANCE_CHANNEL" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.news')}
              </a>
            </div>

            <div className="mt-2">
              <a href="https://www.youtube.com/@aurous.finance" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.videos')}
              </a>
            </div>
          </div>

          <div className="col-md-5 col-lg-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">{t('footer.contacts')}</div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faFileCertificate} />
              </div>
              <div className="flex-md-1 tx-gray-400">
                <p>
                  <span className="notranslate">Rainbow Minerals Mining</span> (Certificate #57510, Sudan)
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="flex-md-1 tx-gray-400">
                <p className="notranslate">12 Sheikh Hospital Street, Al Khurtum, Sudan</p>
              </div>
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="flex-md-1">
                <a className="notranslate tx-gray-400" href="mailto:marketing@aurous.finance">
                  marketing@aurous.finance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
