import { isFuture } from 'date-fns';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { ECurrency } from '@~types/enums';
import type { IUsdInvestPlan } from '@~types/types';

interface ISwapInvestPlanProps {
  planData?: IUsdInvestPlan;
}

export const SwapInvestPlan = memo(({ planData }: ISwapInvestPlanProps) => {
  const { t } = useTranslation();

  const usdWorkUntil = planData?.workUntil ? new Date(planData.workUntil) : null;

  if (!planData) {
    return null;
  }

  return (
    <div className="card-block d-block d-xxl-flex justify-content-between align-items-center align-items-xxl-start align-items-xxxl-center my-3">
      <div className="d-flex justify-content-center justify-content-md-start flex-column flex-sm-row align-items-center align-items-sm-start gap-4 mb-3 mb-xxxl-0 text-center text-sm-start">
        <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.invested')}</div>
          <div className="value">
            <FormattedNumber value={planData.totalUsdInvested} postfix={ECurrency.USDT} />
          </div>
        </div>
        {/* <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.stake-balance')}</div>
          <div className="value">
            <FormattedNumber value={planData.usdInvestmentBody} postfix={ECurrency.USDT} />
          </div>
        </div> */}
        <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.paid')}</div>
          <div className="value">
            <FormattedNumber value={planData.paidUsdPassive} postfix={ECurrency.USDT} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center justify-content-md-start">
        <div className="d-flex d-xxl-block d-xxxl-flex plate-text px-3 gap-3">
          {usdWorkUntil && isFuture(usdWorkUntil) ? (
            <>
              <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.work-until')}</div>
              <div className="value">
                <FormattedDate date={usdWorkUntil} dateFormat="dd.MM.yyyy" />
              </div>
            </>
          ) : (
            <>
              <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.paid')}</div>
              <div className="value">
                <FormattedNumber
                  // value={planData.paidUsdPassive + planData.usdInvestmentBody}
                  value={planData.paidUsdPassive}
                  postfix={ECurrency.USDT}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
