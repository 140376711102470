import { clearAuthTokens, setAuthTokens } from 'axios-jwt';
import { atom } from 'jotai';

import { HIDE_EMPTY_SUB_LINES_LS_KEY, REF_ID_LS_KEY, TOKEN_LS_KEY } from '@~store/constants';
import type { IProfile, IRankInfo, IRankRule, IReferralRule, ITokenBank, IWallet } from '@~types/types';

const emptySubLinesHiddenLocalAtom = atom(localStorage.getItem(HIDE_EMPTY_SUB_LINES_LS_KEY));
const refIdLocalAtom = atom(localStorage.getItem(REF_ID_LS_KEY));
const tokenLocalAtom = atom(localStorage.getItem(TOKEN_LS_KEY));

export const loggedAtom = atom(
  (get) => {
    return !!get(tokenLocalAtom);
  },
  (get, set, isLogged: boolean) => {
    if (!isLogged) {
      clearAuthTokens();
      set(tokenLocalAtom, null);
      localStorage.removeItem(TOKEN_LS_KEY);
    }
  }
);

export const tokenAtom = atom(
  (get) => {
    return get(tokenLocalAtom);
  },
  (get, set, token: string) => {
    setAuthTokens({
      accessToken: token,
      refreshToken: token,
    });
    set(tokenLocalAtom, token);
    localStorage.setItem(TOKEN_LS_KEY, token);
  }
);

export const refIdAtom = atom(
  (get) => {
    return get(refIdLocalAtom);
  },
  (get, set, refId: string | null) => {
    set(refIdLocalAtom, refId);
    if (refId) {
      localStorage.setItem(REF_ID_LS_KEY, refId);
    } else {
      localStorage.removeItem(REF_ID_LS_KEY);
    }
  }
);

export const emptySubLinesHiddenAtom = atom(
  (get) => {
    return get(emptySubLinesHiddenLocalAtom) === '1';
  },
  (get, set, hideEmptySubLines: boolean | null) => {
    set(emptySubLinesHiddenLocalAtom, hideEmptySubLines ? '1' : '0');
    if (hideEmptySubLines) {
      localStorage.setItem(HIDE_EMPTY_SUB_LINES_LS_KEY, hideEmptySubLines ? '1' : '0');
    } else {
      localStorage.removeItem(HIDE_EMPTY_SUB_LINES_LS_KEY);
    }
  }
);

export const isAdminAtom = atom(false);
export const tokenBankAtom = atom<ITokenBank | null>(null);
export const walletsAtom = atom<IWallet[]>([]);
export const profileInfoAtom = atom<IProfile | null>(null);
export const userRankInfoAtom = atom<IRankInfo | null>(null);
export const referralRulesAtom = atom<IReferralRule[]>([]);
export const rankRulesAtom = atom<IRankRule[]>([]);
