import { useAtom } from 'jotai';
import type { PropsWithChildren } from 'react';
import { createContext, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useResponseHandler } from '@~hooks/useResponseHandler';
// import { getProfileInfo, getRankInfo, getRankRules, getReferralRules, getWallets } from '@~network/account';
import { getProfileInfo, getReferralRules, getWallets } from '@~network/account';

import { getTokenBank } from '@~network/public';
import {
  loggedAtom,
  rankRulesAtom,
  tokenBankAtom,
  profileInfoAtom,
  userRankInfoAtom,
  walletsAtom,
  referralRulesAtom,
} from '@~store/account.store';

interface IStateContextProps {
  fetchTokenBank: () => void;
  fetchWallets: () => void;
  fetchProfileInfo: () => void;
  fetchUserRankInfo: () => void;
  loadingWallets: boolean;
  loadingTokenBank: boolean;
  loadingProfileInfo: boolean;
  loadingUserRankInfo: boolean;
}

const StateContext = createContext<IStateContextProps | undefined>(undefined);

export const StateProvider = memo(({ children }: PropsWithChildren) => {
  const [loadingWallets, setLoadingWallets] = useState(true);
  const [loadingTokenBank, setLoadingTokenBank] = useState(true);
  const [loadingProfileInfo, setLoadingProfileInfo] = useState(true);
  const [loadingUserRankInfo, setLoadingUserRankInfo] = useState(true);

  const walletsRequestSentRef = useRef(false);
  const tokenBankRequestSentRef = useRef(false);
  const profileInfoRequestSentRef = useRef(false);
  const userRankInfoRequestSentRef = useRef(false);
  const referralRulesRequestSentRef = useRef(false);
  const rankRulesRequestSentRef = useRef(false);

  const [isLoggedIn] = useAtom(loggedAtom);
  const [, setWallets] = useAtom(walletsAtom);
  const [, setTokenBank] = useAtom(tokenBankAtom);
  const [, setProfileInfo] = useAtom(profileInfoAtom);
  const [, setUserRankInfo] = useAtom(userRankInfoAtom);
  const [, setReferralRules] = useAtom(referralRulesAtom);
  const [, setRankRules] = useAtom(rankRulesAtom);

  const handleResponse = useResponseHandler();

  const handleWalletsRequest = useCallback(() => {
    if (!isLoggedIn || walletsRequestSentRef.current) {
      return;
    }

    walletsRequestSentRef.current = true;
    getWallets()
      .then((response) => {
        setWallets(response);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setLoadingWallets(false);
        walletsRequestSentRef.current = false;
      });
  }, [isLoggedIn, setWallets, handleResponse]);

  const fetchWallets = useCallback(() => {
    setLoadingWallets(true);
    handleWalletsRequest();
  }, [handleWalletsRequest]);

  useEffect(() => {
    handleWalletsRequest();
  }, [handleWalletsRequest]);

  const handleTokenBankRequest = useCallback(() => {
    if (tokenBankRequestSentRef.current) {
      return;
    }

    tokenBankRequestSentRef.current = true;
    // getTokenBank()
    //   .then((response) => {
    //     setTokenBank(response);
    //   })
    //   .catch((response) => {
    //     handleResponse(response.response.data);
    //   })
    //   .finally(() => {
    //     setLoadingTokenBank(false);
    //     tokenBankRequestSentRef.current = false;
    //   });
  // }, [setTokenBank, handleResponse]);
  }, []);

  const fetchTokenBank = useCallback(() => {
    setLoadingTokenBank(true);
    handleTokenBankRequest();
  }, [handleTokenBankRequest]);

  useEffect(() => {
    handleTokenBankRequest();
  }, [handleTokenBankRequest]);

  const handleProfileInfoRequest = useCallback(() => {
    if (!isLoggedIn || profileInfoRequestSentRef.current) {
      return;
    }

    profileInfoRequestSentRef.current = true;
    getProfileInfo()
      .then((response) => {
        setProfileInfo(response);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setLoadingProfileInfo(false);
        profileInfoRequestSentRef.current = false;
      });
  }, [isLoggedIn, setProfileInfo, handleResponse]);

  const fetchProfileInfo = useCallback(() => {
    setLoadingProfileInfo(true);
    handleProfileInfoRequest();
  }, [handleProfileInfoRequest]);

  useEffect(() => {
    handleProfileInfoRequest();
  }, [handleProfileInfoRequest]);

  const handleUserRankInfoRequest = useCallback(() => {
    if (!isLoggedIn || userRankInfoRequestSentRef.current) {
      return;
    }

    userRankInfoRequestSentRef.current = true;
    // getRankInfo()
    //   .then((response) => {
    //     setUserRankInfo(response.data);
    //   })
    //   .catch((response) => {
    //     handleResponse(response.response.data);
    //   })
    //   .finally(() => {
    //     setLoadingUserRankInfo(false);
    //     userRankInfoRequestSentRef.current = false;
    //   });
  // }, [isLoggedIn, setUserRankInfo, handleResponse]);
  }, [isLoggedIn]);

  const fetchUserRankInfo = useCallback(() => {
    setLoadingUserRankInfo(true);
    handleUserRankInfoRequest();
  }, [handleUserRankInfoRequest]);

  useEffect(() => {
    handleUserRankInfoRequest();
  }, [handleUserRankInfoRequest]);

  useEffect(() => {
    if (!isLoggedIn || referralRulesRequestSentRef.current) {
      return;
    }

    referralRulesRequestSentRef.current = true;
    getReferralRules()
      .then((response) => {
        setReferralRules(response || []);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        referralRulesRequestSentRef.current = false;
      });
  }, [isLoggedIn, setReferralRules, handleResponse]);

  useEffect(() => {
    if (!isLoggedIn || rankRulesRequestSentRef.current) {
      return;
    }

    rankRulesRequestSentRef.current = true;
    // getRankRules()
    //   .then((response) => {
    //     setRankRules(response.data || []);
    //   })
    //   .catch((response) => {
    //     handleResponse(response.response.data);
    //   })
    //   .finally(() => {
    //     rankRulesRequestSentRef.current = false;
    //   });
  }, [isLoggedIn]);
  // }, [isLoggedIn, setRankRules, handleResponse]);

  return (
    <StateContext.Provider
      value={{
        fetchTokenBank,
        fetchWallets,
        fetchProfileInfo,
        fetchUserRankInfo,
        loadingWallets,
        loadingTokenBank,
        loadingProfileInfo,
        loadingUserRankInfo,
      }}
    >
      {children}
    </StateContext.Provider>
  );
});

export const useStateUpdate = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useStateUpdate must be used within a StateContext');
  }
  return {
    ...context,
  };
};
