import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faCircleNotch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { useInterval } from '@~hooks/useInterval';

export interface IModalAlertProps {
  okLabel?: string;
  cancelLabel?: string;
  okIcon?: IconDefinition | null;
  cancelIcon?: IconDefinition | null;
  okVariant?: string;
  cancelVariant?: string;
  title?: string;
  size?: 'sm' | 'lg' | 'xl';
  content: JSX.Element | string;
  show?: boolean;
  centered?: boolean;
  proceed?: (success: boolean) => void;
  enableEscape?: boolean;
  noOk?: boolean;
  noCancel?: boolean;
  closeTimeout?: number;
  loading?: boolean;
}

export const ModalAlert = (props: IModalAlertProps) => {
  const { t } = useTranslation();
  const {
    okLabel = t('common.action.continue'),
    cancelLabel = t('common.action.cancel'),
    okIcon = faCheck,
    cancelIcon = faTimes,
    okVariant = 'primary',
    cancelVariant = 'dark',
    size,
    title = '',
    content,
    show = true,
    centered = true,
    proceed = () => {},
    enableEscape = false,
    noOk = false,
    noCancel = false,
    closeTimeout = 0,
    loading = false,
  } = props;

  const [timeLeft, setTimeLeft] = useState(closeTimeout);

  useInterval(
    () => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    },
    timeLeft > 0 ? 1000 : null
  );

  const header = title ? (
    <Modal.Header className="px-5">
      <Modal.Title className="tx-uppercase flex-1 text-center">{title}</Modal.Title>
      {!noCancel && cancelIcon && (
        <button className="btn btn-link py-1 px-3" onClick={() => proceed(false)}>
          <FontAwesomeIcon icon={cancelIcon} />
        </button>
      )}
    </Modal.Header>
  ) : null;

  return (
    <Modal
      show={show}
      size={size}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : 'static'}
      keyboard={enableEscape}
      centered={centered}
      className="pb-3"
    >
      {header}
      <Modal.Body className="py-1 px-5">{content}</Modal.Body>
      <Modal.Footer className="pt-3 wide-buttons justify-content-center px-5">
        {!noCancel && (
          <button className={'btn btn-' + cancelVariant} onClick={() => proceed(false)} disabled={timeLeft !== 0}>
            {timeLeft !== 0 && <FontAwesomeIcon icon={faCircleNotch} className="me-2" spin />}
            {timeLeft === 0 && cancelIcon && <FontAwesomeIcon icon={cancelIcon} className="me-2" />}
            {cancelLabel}
          </button>
        )}
        {!noOk && (
          <button className={'btn ms-2 btn-' + okVariant} onClick={() => proceed(true)} disabled={timeLeft !== 0}>
            {okLabel}
            {timeLeft !== 0 && <FontAwesomeIcon icon={faCircleNotch} className="ms-2" spin />}
            {timeLeft === 0 && okIcon && <IconWithLoading icon={okIcon} className="ms-2" isLoading={loading} />}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
