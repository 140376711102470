import {
  faCirclePlus,
  faMoneyBillTrendUp,
  faMoneySimpleFromBracket,
  faPlaneDeparture,
  faRankingStar,
  faReplyClock,
  faRightLeft,
  faUser,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Translate } from '@~components/translate/Translate';
import type { IPage } from '@~types/navigation';
import { EAccountLink, ESocialLink, ESocialRoute } from '@~types/navigationEnums';

export const accountPages: IPage[] = [
  {
    id: 'deposit',
    icon: <FontAwesomeIcon icon={faCirclePlus} />,
    title: 'global.account-navigation.deposit',
    path: EAccountLink.DEPOSIT,
  },
  // {
  //   id: 'swap',
  //   icon: <FontAwesomeIcon icon={faRightLeft} />,
  //   title: 'global.account-navigation.swap',
  //   badge: (
  //     <div className="badge bg-info">
  //       <Translate i18nKey="common.test" />
  //     </div>
  //   ),
  //   path: EAccountLink.SWAP,
  // },
  {
    id: 'staking',
    icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} />,
    title: 'global.account-navigation.staking',
    path: EAccountLink.STAKING,
  },
  {
    id: 'partners',
    icon: <FontAwesomeIcon icon={faUsers} />,
    title: 'global.account-navigation.partners',
    path: EAccountLink.PARTNERS,
  },
  // {
  //   id: 'rank-system',
  //   icon: <FontAwesomeIcon icon={faRankingStar} />,
  //   title: 'global.account-navigation.rank-system',
  //   path: EAccountLink.RANK_SYSTEM,
  // },
  {
    id: 'operations',
    icon: <FontAwesomeIcon icon={faReplyClock} />,
    title: 'global.account-navigation.history',
    path: EAccountLink.HISTORY,
  },
  {
    id: 'profile',
    icon: <FontAwesomeIcon icon={faUser} />,
    title: 'global.account-navigation.profile',
    path: EAccountLink.PROFILE,
  },
  {
    id: 'withdrawal',
    icon: <FontAwesomeIcon icon={faMoneySimpleFromBracket} />,
    title: 'global.account-navigation.withdrawal',
    path: EAccountLink.WITHDRAWAL,
  },
  // {
  //   id: 'promo-actions',
  //   icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
  //   title: 'global.account-navigation.promo-actions',
  //   path: EAccountLink.PROMO_ACTIONS,
  // },
];

export const socialPages: Array<{ path: ESocialRoute; link: ESocialLink }> = [
  { path: ESocialRoute.INSTAGRAM, link: ESocialLink.INSTAGRAM },
  { path: ESocialRoute.FACEBOOK, link: ESocialLink.FACEBOOK },
  { path: ESocialRoute.TWITTER, link: ESocialLink.TWITTER },
  { path: ESocialRoute.LINKED_IN, link: ESocialLink.LINKED_IN },
  { path: ESocialRoute.REDDIT, link: ESocialLink.REDDIT },
  { path: ESocialRoute.TELEGRAM, link: ESocialLink.TELEGRAM },
  { path: ESocialRoute.TIKTOK, link: ESocialLink.TIKTOK },
  { path: ESocialRoute.MEDIUM, link: ESocialLink.MEDIUM },
  { path: ESocialRoute.BITCOIN_TALK, link: ESocialLink.BITCOIN_TALK },
  { path: ESocialRoute.YOUTUBE, link: ESocialLink.YOUTUBE },
  { path: ESocialRoute.VK, link: ESocialLink.VK },
];
