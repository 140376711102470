import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import { useAtom } from 'jotai';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { Preloader } from '@~components/preloader/Preloader';
import { config } from '@~config';
import { useStateUpdate } from '@~context/StateContext';
import { ChangePasswordModal } from '@~pages/account/profile/modals/ChangePasswordModal';
import { EditProfileModal } from '@~pages/account/profile/modals/EditProfileModal';
import { EditRefIdModal } from '@~pages/account/profile/modals/EditRefIdModal';
import { ManageTelegramModal } from '@~pages/account/profile/modals/ManageTelegramModal';
import { profileInfoAtom } from '@~store/account.store';
import { getRefLink } from '@~helpers/getRefLink';

export const ProfilePage = memo(() => {
  const { t } = useTranslation();

  const [profileInfo] = useAtom(profileInfoAtom);

  const { loadingProfileInfo } = useStateUpdate();

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.profile.title')}</title>
      </Helmet>
      <div id="profile">
        <div>
          <h2 className="heading tx-uppercase d-flex justify-content-between align-items-center">
            <div>{t('pages.account.profile.content.profile.heading')}</div>
            <div>{profileInfo && <EditProfileModal profileInfo={profileInfo} />}</div>
          </h2>
          <div className="profile-block mt-3">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="line mt-4">
                  <div className="label">{t('pages.account.profile.content.profile.name-label')}</div>
                  {loadingProfileInfo && (
                    <div className="value">
                      <Preloader inline />
                    </div>
                  )}
                  {!loadingProfileInfo && (
                    <div className="value" title={profileInfo?.first_name}>
                      {profileInfo?.first_name}
                    </div>
                  )}
                </div>
                <div className="line mt-4">
                  <div className="label">{t('pages.account.profile.content.profile.surname-label')}</div>
                  {loadingProfileInfo && (
                    <div className="value">
                      <Preloader inline />
                    </div>
                  )}
                  {!loadingProfileInfo && (
                    <div className="value" title={profileInfo?.last_name}>
                      {profileInfo?.last_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="line mt-4">
                  <div className="label">{t('pages.account.profile.content.profile.username-label')}</div>
                  {loadingProfileInfo && (
                    <div className="value">
                      <Preloader inline />
                    </div>
                  )}
                  {!loadingProfileInfo && (
                    <div className="value" title={profileInfo?.username}>
                      {profileInfo?.username}
                    </div>
                  )}
                </div>
                <div className="line mt-4">
                  <div className="label">{t('pages.account.profile.content.profile.email-label')}</div>
                  {loadingProfileInfo && (
                    <div className="value">
                      <Preloader inline />
                    </div>
                  )}
                  {!loadingProfileInfo && (
                    <div className="value" title={profileInfo?.email}>
                      {profileInfo?.email}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="heading tx-uppercase d-flex mt-6">{t('pages.account.profile.content.security.heading')}</h2>
          <div className="security-block mt-4">
            <div className="card-block d-inline-flex justify-content-between gap-7 py-2 pr-2">
              <div>{t('pages.account.profile.content.security.password-label')}</div>
              <div>
                <ChangePasswordModal />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="heading tx-uppercase mt-6">{t('pages.account.profile.content.referral.heading')}</h2>
          <div className="profile-block mt-3">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="line mt-4">
                  <div className="label">{t('pages.account.profile.content.referral.referral')}</div>
                  <div className="value" title={profileInfo?.mentor_id}>
                    {profileInfo?.mentor_id}
                  </div>
                </div>
                <div className="line mt-4">
                  <div className="label">
                    {t('pages.account.profile.content.referral.user-ref-id-label')}
                    {/* {profileInfo && <EditRefIdModal profileInfo={profileInfo} />} */}
                  </div>
                  {loadingProfileInfo && (
                    <div className="value">
                      <Preloader inline />
                    </div>
                  )}
                  {!loadingProfileInfo && (
                    <div className="value link-with-button" title={profileInfo?.ref_id}>
                      {profileInfo?.ref_id && (
                        <CopyButton
                          text={profileInfo.ref_id}
                          className="btn btn-link tx-primary p-0"
                          iconClass="tx-primary ml-2"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <CopyButton
                    text={getRefLink(profileInfo)}
                    className="btn btn-link tx-primary p-0 copy-link"
                    iconClass="tx-primary ml-2 p-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tg-block">
          <h2 className="heading tx-uppercase d-flex justify-content-between align-items-center mt-6">
            <div>{t('pages.account.profile.content.tg.heading')}</div>
            <div>
              {profileInfo?.telegramEnabled && (
                <a className="btn btn-sm btn-black tx-primary" href={config.tgBotUrl} rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="tx-primary me-2" />
                  {t('pages.account.profile.content.tg.open-tg-bot')}
                </a>
              )}
            </div>
          </h2>
          <div className="mt-4">
            <div className="card-block d-inline-flex justify-content-between gap-7 py-2 pr-2">
              {t(
                `pages.account.profile.content.tg.${profileInfo?.telegramEnabled ? 'tg-connected' : 'tg-not-connected'}`
              )}
              <div>{profileInfo && <ManageTelegramModal profileInfo={profileInfo} />}</div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
});
