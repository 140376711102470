import { useAtom } from 'jotai';
import { memo, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getReferralInfo } from '@~network/account';
import { getRefLink } from '@~helpers/getRefLink';
import { AffiliateLevel } from '@~pages/account/partners/components/AffiliateLevel';
import { AffiliateResultsByLevels } from '@~pages/account/partners/components/AffiliateResultsByLevels';
import type { IReferralInfo } from '@~types/types';
import { profileInfoAtom } from '@~store/account.store';

export const PartnersPage = memo(() => {
  const { t } = useTranslation();

  const [profileInfo] = useAtom(profileInfoAtom);

  const [referralInfo, setReferralInfo] = useState<IReferralInfo>();

  const handleResponse = useResponseHandler();
  const { fetchUserRankInfo } = useStateUpdate();

  const sentReferralInfoRequestRef = useRef(false);

  useEffect(() => {
    if (sentReferralInfoRequestRef.current) {
      return;
    }

    sentReferralInfoRequestRef.current = true;
    getReferralInfo()
      .then((response) => {
        setReferralInfo(response);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        sentReferralInfoRequestRef.current = false;
      });
  }, [handleResponse]);

  useEffect(() => {
    fetchUserRankInfo();
  }, [fetchUserRankInfo]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.partners.title')}</title>
      </Helmet>
      <div id="partners">
        <h2 className="heading mb-2 tx-uppercase d-flex justify-content-between align-items-center  flex-wrap">
          <div>{t('pages.account.partners.content.heading')}</div>
          <CopyButton
            text={getRefLink(profileInfo)}
            element={t('pages.account.partners.content.partners-link')}
            className="btn btn-link tx-primary p-0 copy-link"
            iconClass="tx-primary ml-2 p-0"
          />
        </h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.partners.content.subheading')}</h6>
        <div className="row affiliate-program">
          <div className="col-12 col-md-5 mb-4">
            <AffiliateLevel referralInfo={referralInfo} />
          </div>
          <div className="col-12 col-md-7">
            <AffiliateResultsByLevels referralInfo={referralInfo} />
          </div>
        </div>
      </div>
    </>
  );
});
