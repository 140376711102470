import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AffiliateProgramModal } from '@~pages/account/partners/modals/AffiliateProgramModal';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Placeholder } from '@~components/placeholder/Placeholder';
import { AffiliateResultsTableModal } from '@~pages/account/partners/modals/AffiliateResultsTableModal';
import { referralRulesAtom, tokenBankAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import type { IReferralInfo, IReferralLine } from '@~types/types';

interface IAffiliateResultsByLevelsProps {
  referralInfo: IReferralInfo | undefined;
}

export const AffiliateResultsByLevels = memo(({ referralInfo }: IAffiliateResultsByLevelsProps) => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);
  const [referralRules] = useAtom(referralRulesAtom);

  const [selectedLevel, setSelectedLevel] = useState(1);
  const [selectedLine, setSelectedLine] = useState<IReferralLine>();

  useEffect(() => {
    if (referralInfo) {
      setSelectedLine(referralInfo.lines[0]);
    }
  }, [referralInfo]);

  const lineAftEarnedInUsdt = useMemo(() => {
    if (tokenBank && selectedLine) {
      return selectedLine.earn * tokenBank.aufPrice;
    }
    return 0;
  }, [tokenBank, selectedLine]);

  const lineAftMissedInUsdt = useMemo(() => {
    if (tokenBank && selectedLine) {
      return selectedLine.lose * tokenBank.aufPrice;
    }
    return 0;
  }, [tokenBank, selectedLine]);

  const lineAftTurnoverInUsdt = useMemo(() => {
    if (tokenBank && selectedLine) {
      return selectedLine.aufTurnover * tokenBank.aufPrice;
    }
    return 0;
  }, [tokenBank, selectedLine]);

  const lineGatTurnoverInUsdt = useMemo(() => {
    if (tokenBank && selectedLine) {
      return selectedLine.gatTurnover * tokenBank.gatPrice;
    }
    return 0;
  }, [tokenBank, selectedLine]);

  const lineTotalTurnoverInUsdt = useMemo(() => {
    if (selectedLine) {
      return lineAftTurnoverInUsdt + lineGatTurnoverInUsdt + selectedLine.usdTurnover;
    }
    return 0;
  }, [lineAftTurnoverInUsdt, lineGatTurnoverInUsdt, selectedLine]);

  return (
    <div className="card-block">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-3">
          <Dropdown>
            <Dropdown.Toggle variant="transparent" className="affiliate-level-select notranslate">
              <div className="d-inline-flex gap-4">
                <span>{t(`pages.account.partners.content.affiliate-program.level`)}</span>
                <span>{selectedLevel}</span>
              </div>
              <FontAwesomeIcon icon={faAngleDown} className="caret" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {referralRules.map((rule, index) => (
                <Dropdown.Item
                  key={rule.line}
                  className={selectedLevel === rule.line ? 'active' : ''}
                  onClick={() => {
                    setSelectedLevel(rule.line);
                    setSelectedLine(referralInfo?.lines[rule.line - 1]);
                  }}
                >
                  {t(`pages.account.partners.content.affiliate-program.level`)} {rule.line} (
                  {t(`pages.account.partners.content.affiliate-program.partners`)}:{' '}
                  {referralInfo?.lines[index].referralsCount})
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <AffiliateResultsTableModal referralInfo={referralInfo} />
        </div>
        <div className="mt-2">
          <AffiliateProgramModal referralRules={referralRules} />
        </div>
      </div>
      <div className="d-flex flex-wrap mt-4 gap-4">
        <div>
          <div className="tx-uppercase tx-spacing-1">{t('pages.account.partners.content.affiliate-program.line')}</div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                {selectedLine.line} (
                <FormattedNumber key="percents" value={selectedLine.rewardPercents} postfix="%" className="tx-bold" />)
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.partners')}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? selectedLine.referralsCount : <Placeholder width="50px" />}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.earned')}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={selectedLine.earn}
                  postfix={ECurrency.USDT}
                  decimals={2}
                  floor
                />
                {lineAftEarnedInUsdt > 0 && (
                  <FormattedNumber
                    className="d-block tx-normal tx-14 tx-italic"
                    value={lineAftEarnedInUsdt}
                    suffix="≈$"
                    floor
                  />
                )}
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.missed')}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={selectedLine.lose}
                  postfix={ECurrency.USDT}
                  decimals={2}
                  floor
                />
                {lineAftMissedInUsdt > 0 && (
                  <FormattedNumber
                    className="d-block tx-normal tx-14 tx-italic"
                    value={lineAftMissedInUsdt}
                    suffix="≈$"
                    floor
                  />
                )}
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        {/* <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.currency-turnover', {
              currency: ECurrency.AFT,
            })}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={selectedLine.aufTurnover}
                  postfix={ECurrency.AFT}
                  decimals={2}
                  floor
                />
                {lineAftTurnoverInUsdt > 0 && (
                  <FormattedNumber
                    className="d-block tx-normal tx-14 tx-italic"
                    value={lineAftTurnoverInUsdt}
                    suffix="≈$"
                    floor
                  />
                )}
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.currency-turnover', {
              currency: ECurrency.GAT,
            })}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={selectedLine.gatTurnover}
                  postfix={ECurrency.GAT}
                  decimals={2}
                  floor
                />
                {lineGatTurnoverInUsdt > 0 && (
                  <FormattedNumber
                    className="d-block tx-normal tx-14 tx-italic"
                    value={lineGatTurnoverInUsdt}
                    suffix="≈$"
                    floor
                  />
                )}
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.currency-turnover', {
              currency: ECurrency.USDT,
            })}
          </div>
          <div className="tx-gray-600 tx-bold">
            {selectedLine ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={selectedLine.usdTurnover}
                  postfix={ECurrency.USDT}
                  decimals={2}
                  floor
                />
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div>
        <div>
          <div className="tx-uppercase tx-spacing-1">
            {t('pages.account.partners.content.affiliate-program.total-turnover')}
          </div>
          <div className="tx-gray-600 tx-bold">
            {lineTotalTurnoverInUsdt ? (
              <>
                <FormattedNumber
                  className="d-block"
                  value={lineTotalTurnoverInUsdt}
                  postfix={ECurrency.USDT}
                  decimals={2}
                  floor
                />
              </>
            ) : (
              <Placeholder width="50px" />
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
});
