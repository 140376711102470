import { faSackDollar } from '@fortawesome/pro-duotone-svg-icons/faSackDollar';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
import { Preloader } from '@~components/preloader/Preloader';
import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { Translate } from '@~components/translate/Translate';
import { useStateUpdate } from '@~context/StateContext';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getGatInvestPlans, getInvestPlan, getUsdInvestPlan, invest, investGat, investUsd } from '@~network/account';
import { SwapInvestPlan } from '@~pages/account/staking/plans/SwapInvestPlan';
import { AftInvestPlan } from '@~pages/account/staking/plans/AftInvestPlan';
import { GatInvestPlan } from '@~pages/account/staking/plans/GatInvestPlan';
import { EarlyWithdrawalModal } from '@~pages/account/staking/modals/EarlyWithdrawalModal';
import { AftPlanInfoModal } from '@~pages/account/staking/modals/AftPlanInfoModal';
import { GatPlanInfoModal } from '@~pages/account/staking/modals/GatPlanInfoModal';
import { SwapPlanInfoModal } from '@~pages/account/staking/modals/SwapPlanInfoModal';
import { walletsAtom } from '@~store/account.store';
import { ECurrency, EInvestPlanStatus } from '@~types/enums';
import type { IGatInvestPlan, IInvestPlan, IUsdInvestPlan } from '@~types/types';

// const availablePlans = [ECurrency.USDT, ECurrency.AFT, ECurrency.GAT];
const availablePlans = [ECurrency.USDT, ECurrency.AFT];

interface IPlanData {
  id: ECurrency;
  label: string;
  langKey: string;
  investTokens: ECurrency[];
}

const currencyMap: Record<ECurrency, IPlanData> = {
  [ECurrency.USD]: { id: ECurrency.USDT, label: 'SWAP', langKey: 'swap-plan', investTokens: [ECurrency.USDT] },
  [ECurrency.USDT]: { id: ECurrency.USDT, label: 'Plan 1', langKey: 'swap-plan', investTokens: [ECurrency.USDT] },
  [ECurrency.AUF]: {
    id: ECurrency.AFT,
    label: 'AFT',
    langKey: 'aft-plan',
    investTokens: [ECurrency.USDT, ECurrency.AFT],
  },
  [ECurrency.AFT]: {
    id: ECurrency.AFT,
    label: 'Plan 2',
    langKey: 'aft-plan',
    investTokens: [ECurrency.USDT],
  },
  [ECurrency.GAT]: {
    id: ECurrency.GAT,
    label: 'GAT',
    langKey: 'gat-plan',
    investTokens: [ECurrency.USDT, ECurrency.AFT, ECurrency.GAT],
  },
};

export const StakingPage = memo(() => {
  const { t } = useTranslation();

  const [wallets] = useAtom(walletsAtom);

  const [selectedCurrency, setSelectedCurrency] = useState<ECurrency | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvestLoading, setInvestLoading] = useState(false);
  const [investAmount, setInvestAmount] = useState<string>();
  const [investToken, setInvestToken] = useState<ECurrency>();
  const [investMaxAmount, setInvestMaxAmount] = useState<number>(0);

  const [investPlanAft, setInvestPlanAft] = useState<IInvestPlan>();
  const [investPlansGat, setInvestPlansGat] = useState<IGatInvestPlan[]>([]);
  const [investPlanSwap, setInvestPlanSwap] = useState<IUsdInvestPlan[]>([]);

  const sentForAftPlanRef = useRef(false);
  const sentForGatPlanRef = useRef(false);
  const sentForSwapPlanRef = useRef(false);
  const sentInvestRequestRef = useRef(false);

  const { fetchWallets, fetchTokenBank } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const options: ISwitcherOption<ECurrency>[] = useMemo(
    () => availablePlans.map((currency) => ({ value: currency, label: currencyMap[currency].label })),
    []
  );

  const handleValueChange = useCallback((value: ECurrency) => {
    setInvestAmount(undefined);
    setSelectedCurrency(value);
  }, []);

  const fetchAftInvestPlan = useCallback(() => {
    setIsLoading(true);
    getUsdInvestPlan()
      .then((response) => setInvestPlanSwap(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  const fetchGatInvestPlan = useCallback(() => {
    setIsLoading(true);
    getGatInvestPlans()
      .then((response) => setInvestPlansGat(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  const fetchSwapInvestPlan = useCallback(() => {
    setIsLoading(true);
    getUsdInvestPlan()
      .then((response) => setInvestPlanSwap(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  useEffect(() => {
    if (selectedCurrency !== ECurrency.AFT || sentForAftPlanRef.current) {
      return;
    }
    sentForAftPlanRef.current = true;
    fetchSwapInvestPlan();
  }, [selectedCurrency, fetchSwapInvestPlan]);

  // useEffect(() => {
  //   if (selectedCurrency !== ECurrency.GAT || sentForGatPlanRef.current) {
  //     return;
  //   }
  //   sentForGatPlanRef.current = true;
  //   fetchGatInvestPlan();
  // }, [selectedCurrency, fetchGatInvestPlan]);

  useEffect(() => {
    if (selectedCurrency !== ECurrency.USDT || sentForSwapPlanRef.current) {
      return;
    }
    sentForSwapPlanRef.current = true;
    fetchSwapInvestPlan();
  }, [selectedCurrency, fetchSwapInvestPlan]);

  useEffect(() => {
    setSelectedCurrency(availablePlans[0]);
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      setInvestToken(currencyMap[selectedCurrency].investTokens[0]);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    let investMaxAmountValue = 0;
    if (investToken && wallets.length > 0) {
      const foundWallet = wallets.find(({ currency }) => mapCurrency(currency) === investToken);
      if (foundWallet) {
        investMaxAmountValue = new BigNumber(foundWallet.balance).decimalPlaces(2, 1).toNumber();
      }
    }
    setInvestAmount(undefined);
    setInvestMaxAmount(investMaxAmountValue);
  }, [investToken, wallets]);

  const handleInvest = useCallback(() => {
    if (sentInvestRequestRef.current || wallets.length === 0) {
      return;
    }

    const investTokenWallet = wallets.find(({ currency }) => mapCurrency(currency) === investToken);
    if (!investTokenWallet) {
      return;
    }

    if (investAmount && Number(investAmount) > investTokenWallet.balance) {
      handleResponse(t('error.not-enough-money') + '', true);
      return false;
    }

    if (!investAmount || Number(investAmount) <= 0) {
      handleResponse(t('error.wrong-invest-amount', { currency: ECurrency.USDT }) + '', true);
      return;
    }

    if ((selectedCurrency == 'USDT' && Number(investAmount) < 100000) || (selectedCurrency == 'USDT' && Number(investAmount) >= 200000)) {
      handleResponse(t('error.wrong-invest-amount', { currency: ECurrency.USDT }) + '', true);
      return;
    }

    if ((selectedCurrency == 'AFT' && Number(investAmount) < 200000) || selectedCurrency == 'AFT' && Number(investAmount) > 1200000) {
      handleResponse(t('error.wrong-invest-amount', { currency: ECurrency.USDT }) + '', true);
      return;
    }

    sentInvestRequestRef.current = true;

    confirmAlert({
      title: t('pages.account.staking.content.invest') + '',
      okLabel: t('pages.account.staking.content.invest') + '',
      okVariant: 'success',
      content: (
        <div className="modal-content-bg">
          <Translate
            i18nKey={'pages.account.staking.content.invest-confirmation'}
            values={{
              amount: investAmount.toLocaleString(),
              currency: investToken,
              investPlan: selectedCurrency ? currencyMap[selectedCurrency].label : '',
            }}
          />
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        if (selectedCurrency === ECurrency.AFT) {
          setInvestLoading(true);
          invest(Number(investAmount), investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken,
                    invest: currencyMap[selectedCurrency].label,
                  }) + '',
                  false
                );
                fetchAftInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else if (selectedCurrency === ECurrency.GAT) {
          setInvestLoading(true);

          investGat(Number(investAmount), investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken,
                    invest: currencyMap[selectedCurrency].label,
                  }) + '',
                  false
                );
                fetchGatInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else if (selectedCurrency === ECurrency.USDT) {
          setInvestLoading(true);

          investUsd(Number(investAmount), investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken,
                    invest: currencyMap[selectedCurrency].label,
                  }) + '',
                  false
                );
                fetchSwapInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else {
          sentInvestRequestRef.current = false;
        }
      } else {
        sentInvestRequestRef.current = false;
      }
    });
  }, [
    t,
    selectedCurrency,
    investToken,
    investAmount,
    wallets,
    handleResponse,
    fetchAftInvestPlan,
    fetchGatInvestPlan,
    fetchSwapInvestPlan,
    fetchWallets,
    fetchTokenBank,
  ]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.staking.title')}</title>
      </Helmet>
      <div id="staking">
        <h2 className="heading mb-2 tx-uppercase">{t('pages.account.staking.content.heading')}</h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.staking.content.subheading')}</h6>
        <div className="switcher-holder d-flex align-items-center">
          <RadioSwitcher<ECurrency>
            id="wallet-currency"
            options={options}
            handleValueChange={handleValueChange}
            selectedValue={selectedCurrency}
          />
          <div className="ml-3 d-flex align-items-center">
            {!isLoading && selectedCurrency === ECurrency.AFT && <AftPlanInfoModal />}
            {/* {!isLoading && selectedCurrency === ECurrency.GAT && <GatPlanInfoModal />} */}
            {!isLoading && selectedCurrency === ECurrency.USDT && <SwapPlanInfoModal />}
          </div>
        </div>
        {selectedCurrency && (
          <>
            <div className="staking-data">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="card-block text-center text-lg-start py-1 px-4 mt-3 d-inline-block flex-nowrap">
                  <Translate i18nKey={`pages.account.staking.content.${currencyMap[selectedCurrency].langKey}.intro`} />
                </div>
                {/* {!isLoading && selectedCurrency === ECurrency.USDT && (
                  <div className="card-block text-center text-lg-start py-1 px-4 mt-3 d-inline-block tx-uppercase flex-nowrap">
                    {t(`pages.account.staking.content.swap-plan.exit`)}{' '}
                    <EarlyWithdrawalModal fetchSwapInvestPlan={fetchSwapInvestPlan} planData={investPlanSwap} />
                  </div>
                )} */}
              </div>
              {isLoading && (
                <div className="card-block ht-150 d-flex my-3 justify-content-center align-items-center">
                  <Preloader inline />
                </div>
              )}
              {/* {!isLoading && selectedCurrency === ECurrency.AFT && <AftInvestPlan planData={investPlanAft} />} */}
              {/* {!isLoading && selectedCurrency === ECurrency.GAT && <GatInvestPlan plansData={investPlansGat} />} */}
              {!isLoading && selectedCurrency === ECurrency.AFT && investPlanSwap.map((plan) => (plan.totalUsdInvested >= 200000 && plan.totalUsdInvested <= 1200000? <SwapInvestPlan key={plan.id} planData={plan} />: null))}
              {!isLoading && selectedCurrency === ECurrency.USDT && investPlanSwap.map((plan) => (plan.totalUsdInvested >= 100000 && plan.totalUsdInvested < 200000? <SwapInvestPlan key={plan.id} planData={plan} />: null))}
            </div>
            <div className="invest-block mt-6">
              <h5 className="tx-normal">
                {t('pages.account.staking.content.invest-into')} {currencyMap[selectedCurrency].label}
              </h5>
              <InputGroup className="mt-3 mb-1">
                <Form.Control
                  type="text"
                  size="lg"
                  placeholder="0.00"
                  value={investAmount !== undefined ? investAmount : ''}
                  id="investAmount"
                  onChange={(event) => {
                    let value = event.target.value.replace(/[^\d.]/g, '');
                    if (value.indexOf('.') !== -1) {
                      let count = 0;
                      for (let i = 0; i < value.length; i++) {
                        if (value[i] === '.') {
                          count++;
                        }
                      }
                      console.log(count)
                      if (count > 1) {
                        value = value.substring(0, value.indexOf('.') + 1) + value.substring(value.indexOf('.') + 1).replace(/\./g, '');
                      } else if (count === 1) {
                        value = value.substring(0, value.indexOf('.') + 1) + value.substring(value.indexOf('.') + 1, value.indexOf('.') + 3)
                      }
                    }
                    console.log(value)
                    setInvestAmount(value ? value : undefined)
                  }}
                  disabled={isLoading}
                />
                <DropdownButton
                  variant="outline-primary"
                  title={
                    <div className="token-select notranslate">
                      {investToken} <FontAwesomeIcon icon={faAngleDown} className="caret" />
                    </div>
                  }
                  align="start"
                >
                  {currencyMap[selectedCurrency].investTokens.map((currency) => (
                    <Dropdown.Item
                      key={currency}
                      className={currency === investToken ? 'active' : ''}
                      onClick={() => setInvestToken(currency)}
                    >
                      {currency}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <div className="ml-3 tx-gray-600">
                {t('common.available')}:
                <FormattedNumber
                  value={investMaxAmount}
                  postfix={investToken}
                  decimals={2}
                  floor
                  className="text-decoration-underline ms-1 cur-pointer"
                  onClick={() => setInvestAmount(investMaxAmount.toString())}
                />
              </div>
              <div className="mt-5">
                <button className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0" onClick={handleInvest}>
                  <IconWithLoading icon={faSackDollar} isLoading={isInvestLoading} className="me-3" />
                  {t('pages.account.staking.content.invest')}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});
