import { memo, useMemo } from 'react';
import { Accordion } from 'react-bootstrap';

import { Translate } from '@~components/translate/Translate';
import { FaqToggle } from '@~pages/landing/sections/faq/components/fag-toggle/FaqToggle';
import { IFaqBlock } from '@~types/types';

import styles from './Faq.module.scss';

export const Faq = memo(() => {
  const faqBlocks = useMemo(() => {
    let i = 1;
    const blocks: IFaqBlock[] = [];

    while (i <= 7) {
      blocks.push({
        id: `${i}`,
        title: <Translate i18nKey={'pages.faq.block-' + i + '.title'} />,
        text: <Translate i18nKey={'pages.faq.block-' + i + '.text'} />,
      });
      i++;
    }

    return blocks;
  }, []);

  return (
    <section className="container pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="faq">
      <h1 className="mb-6 tx-center">FAQ</h1>

      <div className={styles.root}>
        <Accordion>
          {faqBlocks.map((block) => (
            <div className="accordion-item" key={block.id}>
              <FaqToggle eventKey={block.id}>{block.title}</FaqToggle>
              <Accordion.Collapse eventKey={block.id}>
                <div className="tx-16 tx-normal pb-4">{block.text}</div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    </section>
  );
});
