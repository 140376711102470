import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { mapCurrency } from '@~helpers/mapCurrency';
import { rankRulesAtom, tokenBankAtom, userRankInfoAtom, walletsAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';

// const currencies = [ECurrency.AFT, ECurrency.GAT, ECurrency.USDT];
const currencies = [ECurrency.USDT];
const hashToCurrencyMap: Record<string, ECurrency> = {
  // [ECurrency.AFT]: ECurrency.AFT,
  // [ECurrency.GAT]: ECurrency.GAT,
  [ECurrency.USDT]: ECurrency.USDT,
};

export const UserStats = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [tokenBank] = useAtom(tokenBankAtom);
  const [userRankInfo] = useAtom(userRankInfoAtom);
  const [rankRules] = useAtom(rankRulesAtom);
  const [wallets] = useAtom(walletsAtom);

  const currencyHash = hashToCurrencyMap[location.hash.slice(1)] || ECurrency.USDT;
  const [selectedToken, setSelectedToken] = useState(currencyHash);

  const rankName = useMemo(() => {
    if (!userRankInfo || rankRules.length < 1) {
      return '';
    }
    return rankRules.find(({ level }) => level === userRankInfo.rankLevel)?.name || '---';
  }, [userRankInfo, rankRules]);

  const selectedWallet = useMemo(() => {
    let amount = 0;
    if (wallets.length > 0) {
      const wallet = wallets.find(({ currency }) => mapCurrency(currency) === selectedToken);
      if (wallet) {
        amount = wallet.balance;

        // if (selectedToken === ECurrency.AFT && tokenBank?.aufPrice) {
        //   balance = wallet.balance * tokenBank.aufPrice + (wallet.metadata.fakeBalance || 0) * tokenBank.aufPrice;
        // } else if (selectedToken === ECurrency.AFT && tokenBank?.gatPrice) {
        //   balance = wallet.balance * tokenBank.gatPrice + (wallet.metadata.fakeBalance || 0) * tokenBank.gatPrice;
        // } else {
        //   balance = wallet.usdBalance + (wallet.metadata.fakeBalance || 0);
        // }
      }
    }
    return {
      amount
    };
  }, [wallets, selectedToken]);
// }, [wallets, tokenBank, selectedToken]);

  // const handleTokenChange = useCallback(
  //   (currency: ECurrency) => () => {
  //     setSelectedToken(currency);
  //     navigate(`#${currency}`, { replace: true });
  //   },
  //   [navigate]
  // );

  return (
    <div id="user-stats" className="card account">
      {/* <div className="user-rank">
        <div className="notranslate rank-label tx-uppercase">{t('components.user-stats.rank-label')}</div>
        <div className="notranslate rank-value tx-uppercase">{rankName}</div>
      </div> */}
      {/* <div className="token-selector">
        <Dropdown>
          <Dropdown.Toggle variant="transparent" className="token-select notranslate">
            {selectedToken} <FontAwesomeIcon icon={faAngleDown} className="caret" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currencies.map((currency) => (
              <Dropdown.Item
                key={currency}
                className={currency === selectedToken ? 'active' : ''}
                onClick={handleTokenChange(currency)}
              >
                {currency}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      {/* <div className="info-block">
        {selectedToken === ECurrency.AFT && (
          <>
            <div className="info-line">
              <span className="label">{t('components.user-stats.token-price')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.aufPrice} postfix={ECurrency.USDT} decimals={5} />
              </span>
            </div>
            <div className="info-line">
              <span className="label">{t('components.user-stats.until-increase')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.nextAufUpdateIn} postfix={ECurrency.USDT} decimals={2} />
              </span>
            </div>
          </>
        )}
        {selectedToken === ECurrency.GAT && (
          <>
            <div className="info-line">
              <span className="label">{t('components.user-stats.token-price')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.gatPrice} postfix={ECurrency.USDT} decimals={5} />
              </span>
            </div>
            <div className="info-line">
              <span className="label">{t('components.user-stats.market-cap')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.gatSoldCount} postfix={ECurrency.GAT} decimals={2} />
              </span>
            </div>
          </>
        )}
      </div> */}
      <div className="user-amount">
        <div className="tokens-amount">
          <FormattedNumber value={selectedWallet.amount} postfix={selectedToken} decimals={2} />
        </div>
        {/* {selectedWallet.balance > 0 && selectedToken !== ECurrency.USDT && (
          <div className="tokens-balance">
            <FormattedNumber value={selectedWallet.balance} className="tx-italic" suffix={'≈$'} decimals={2} />
          </div>
        )} */}
      </div>
    </div>
  );
});
