import axios from 'axios';
import { applyAuthTokenInterceptor } from 'axios-jwt';

import { config } from '@~config';
import { TOKEN_LS_KEY } from '@~store/constants';

axios.defaults.withCredentials = true;

export const axiosInstance = axios.create({ baseURL: `${config.apiUrl}/users`, withCredentials: true });

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh: () => {
    return new Promise((resolve) => {
      resolve(localStorage.getItem(TOKEN_LS_KEY) || '');
    });
  }, // async function that takes a refreshToken and returns a promise the resolves in a fresh accessToken
  header: 'Authorization', // header name
  headerPrefix: 'Bearer ', // header value prefix
});

interface IApiProps<P> {
  method: 'post' | 'get' | 'delete';
  path: string;
  data?: P;
}

export function api<P, T>({ method, path, data }: IApiProps<P>): Promise<T> {
  const url = method === 'get' && data ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
    })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((response) => {
        reject(response);
      });
  });
}
