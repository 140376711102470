import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTable, faThList } from '@fortawesome/pro-regular-svg-icons';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { Table } from '@~components/table/Table';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getTransactions } from '@~network/account';
import { TransactionDetails } from '@~pages/account/history/components/TransactionDetails';
import { ECurrency, EHistoryFilter, ETableView, ETransactionStatus, ETransactionType } from '@~types/enums';
import type { IHeader, ITableViewIcon, ITransaction } from '@~types/types';

const tableViews: ITableViewIcon[] = [
  { view: ETableView.TABLE, icon: faTable, translation: 'components.table.table-view' },
  { view: ETableView.LIST, icon: faThList, translation: 'components.table.cards-view' },
];

const tableHeaders: IHeader[] = [
  { key: 'date', translateKey: 'pages.account.operations.content.header.date' },
  { key: 'type', translateKey: 'pages.account.operations.content.header.type' },
  { key: 'amount', translateKey: 'pages.account.operations.content.header.amount' },
  { key: 'id', translateKey: 'pages.account.operations.content.header.id' },
  { key: 'status', translateKey: 'pages.account.operations.content.header.status' },
  { key: 'details', translateKey: 'pages.account.operations.content.header.details' },
];

const typesFilterMap: Record<EHistoryFilter, ETransactionType[]> = {
  [EHistoryFilter.ALL]: [],
  [EHistoryFilter.DEPOSITS]: [
    ETransactionType.DEPOSIT
  ],
  [EHistoryFilter.INVESTMENTS]: [
    ETransactionType.STAKE,
    ETransactionType.STAKE_PROFIT
  ],
  // [EHistoryFilter.BONUSES]: [ETransactionType.RANK_BONUS_PAYOUT],
  [EHistoryFilter.REFERRALS]: [
    ETransactionType.REFERRAL_PAYOUT
  ],
  [EHistoryFilter.WITHDRAWALS]: [ETransactionType.WITHDRAWAL],
  // [EHistoryFilter.OTHERS]: [ETransactionType.GAT_GREAT_PASSIVE_PAYOUT],
};

export const HistoryPage = memo(() => {
  const { t } = useTranslation();

  const handleResponse = useResponseHandler();

  const [tableView, setTableView] = useState(ETableView.TABLE);
  const [dataFilter, setDataFilter] = useState(EHistoryFilter.ALL);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const sentForTransactionsRef = useRef(false);

  const fetchTransactions = useCallback(
    (pageNr = 1, types: ETransactionType[] = []) => {
      if (sentForTransactionsRef.current) {
        return;
      }

      setLoading(true);
      sentForTransactionsRef.current = true;

      getTransactions(pageNr, undefined, types)
        .then((response) => {
          setTransactions(response.content);
          setTotal(response.totalElements);
        })
        .catch((response) => {
          handleResponse(response.response.data);
        })
        .finally(() => {
          sentForTransactionsRef.current = false;
          setLoading(false);
          setLoaded(true);
        });
    },
    [handleResponse]
  );

  const tableViewsOptions: ISwitcherOption<ETableView>[] = useMemo(
    () =>
      tableViews.map((viewIcon) => ({
        value: viewIcon.view,
        label: <FontAwesomeIcon icon={viewIcon.icon} title={t(viewIcon.translation) + ''} />,
      })),
    [t]
  );

  const tableRows = useMemo(
    () =>
      transactions.map((transaction) => {
        let transactionStatus = t('pages.account.operations.content.status.' + transaction.status);
        if (
          transaction.action_type === ETransactionType.WITHDRAWAL &&
          transaction.status === ETransactionStatus.COMPLETE &&
          !transaction.metadata?.withdrawalAddress
        ) {
          transactionStatus = t('pages.account.operations.content.status.IN_PROGRESS');
        }

        return {
          id: transaction.uniq_code,
          cells: [
            <FormattedDate key="date" date={transaction.createdAt} />,
            t('pages.account.operations.content.type.' + transaction.action_type),
            <>
              {transaction.operations.map((operation) => (
                <div key={`transaction-${transaction.uniq_code}-operation-${operation.id}`} className="tx-bold">
                  <FormattedNumber
                    value={operation.balanceDiff}
                    floor
                    withSign
                    postfix={mapCurrency(operation.currency as ECurrency)}
                    className="d-block"
                  />
                  {/* {operation.currency !== ECurrency.USD && (
                    <FormattedNumber
                      value={operation.balanceDiffUsd}
                      suffix="≈$"
                      floor
                      className="d-block tx-normal tx-14 tx-italic"
                    />
                  )} */}
                </div>
              ))}
            </>,
            <CopyButton
              key="transaction-id"
              text={transaction.uniq_code}
              // element={<span>{transaction.id.split('-')[0]}</span>}
              element={<span>{transaction.uniq_code}</span>}
            />,
            transactionStatus,
            <TransactionDetails key="details" entry={transaction} />,
          ],
        };
      }),
    [transactions, t]
  );

  const handleViewChange = useCallback((value: ETableView) => {
    setTableView(value);
  }, []);

  const handlePageChange = useCallback((pageNr: number) => {
    setPage(pageNr);
  }, []);

  useEffect(() => {
    const types: ETransactionType[] = [];
    if (dataFilter !== EHistoryFilter.ALL) {
      types.push(...typesFilterMap[dataFilter]);
    }
    fetchTransactions(page, types);
  }, [fetchTransactions, page, dataFilter]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.operations.title')}</title>
      </Helmet>
      <div id="history">
        <h2 className="heading d-flex justify-content-between align-items-center mb-6">
          <div className="d-flex justify-content-start align-items-center gap-4">
            <div className="d-none d-lg-inline-flex">
              <RadioSwitcher<ETableView>
                id="table-view"
                options={tableViewsOptions}
                handleValueChange={handleViewChange}
                selectedValue={tableView}
                onlyIcons={true}
              />
            </div>
            <div className="tx-uppercase">{t('pages.account.operations.content.heading')}</div>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle variant="transparent" className="filter-select notranslate">
              {t(`pages.account.operations.content.filter.${dataFilter}`)}{' '}
              <FontAwesomeIcon icon={faAngleDown} className="caret" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(EHistoryFilter).map((filter) => (
                <Dropdown.Item
                  key={filter}
                  className={filter === dataFilter ? 'active' : ''}
                  onClick={() => {
                    setDataFilter(filter);
                    setPage(0);
                  }}
                >
                  {t(`pages.account.operations.content.filter.${filter}`)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </h2>
        <Table
          id="history-table"
          tableView={tableView}
          headers={tableHeaders}
          rows={tableRows}
          isLoading={loading}
          dataLoaded={isLoaded}
          total={total}
          handlePageChange={handlePageChange}
          rowsPerPage={10}
          noDataTranslationKey="pages.account.operations.content.no-transactions"
        />
      </div>
    </>
  );
});
