import { faMoneyFromBracket } from '@fortawesome/pro-duotone-svg-icons/faMoneyFromBracket';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { useStateUpdate } from '@~context/StateContext';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { withdraw } from '@~network/account';
import { tokenBankAtom, walletsAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
import { Translate } from '@~components/translate/Translate';

const availableOptions = [ECurrency.USDT];
// const availableOptions = [ECurrency.USDT, ECurrency.AFT, ECurrency.GAT];

export const WithdrawalPage = memo(() => {
  const { t } = useTranslation();

  const [wallets] = useAtom(walletsAtom);
  const [tokenBank] = useAtom(tokenBankAtom);

  const [selectedCurrency, setSelectedCurrency] = useState<ECurrency>();
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>();
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState<number>(0);
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  const [convertedAmount, setConvertedAmount] = useState(0);

  const sentWithdrawRequestRef = useRef(false);

  const { fetchWallets } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const options: ISwitcherOption<ECurrency>[] = useMemo(
    () => availableOptions.map((currency) => ({ value: currency, label: currency })),
    []
  );

  const handleCurrencyChange = useCallback((value: ECurrency) => {
    setSelectedCurrency(value);
  }, []);

  const isAddressValid = useMemo((): boolean => {
    return RegExp(/^T[A-Za-z1-9]{33}$/).test(withdrawalAddress);
  }, [withdrawalAddress]);

  const isDataValid = useCallback(() => {
    if (!selectedCurrency || wallets.length === 0) {
      return false;
    }

    if (!Number(withdrawalAmount) || Number(withdrawalAmount) < 10) {
        handleResponse(t('error.wrong-amount') + '', true);
      return false;
    }

    const foundWallet = wallets.find(({ currency }) => mapCurrency(currency) === selectedCurrency);
    if (!foundWallet) {
      return false;
    }

    if (Number(withdrawalAmount) && Number(withdrawalAmount) > foundWallet.balance) {
      handleResponse(t('error.not-enough-money') + '', true);
      return false;
    }

    if (!isAddressValid) {
      handleResponse(t('error.wrong-usdt-address') + '', true);
      return false;
    }

    return true;
  }, [isAddressValid, withdrawalAmount, wallets, selectedCurrency, handleResponse, t]);

  const handleWithdraw = useCallback(() => {
    if (sentWithdrawRequestRef.current) {
      return;
    }

    if (!withdrawalAmount || !selectedCurrency) {
      return;
    }

    const foundWallet = wallets.find(({ currency }) => mapCurrency(currency) === selectedCurrency);
    if (!foundWallet) {
      return;
    }

    if (!isDataValid()) {
      return;
    }

    confirmAlert({
      title: t('pages.account.withdrawal.content.heading') + '',
      okLabel: t('pages.account.withdrawal.content.withdraw') + '',
      okVariant: 'success',
      size: 'lg',
      content: (
        <div className="modal-content-bg word-break">
          <Translate
            i18nKey={'pages.account.withdrawal.content.withdraw-confirmation'}
            values={{
              amount: withdrawalAmount.toLocaleString(),
              currency: selectedCurrency,
              address: withdrawalAddress,
            }}
          />
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        setIsLoading(true);
        sentWithdrawRequestRef.current = true;

        withdraw(Number(withdrawalAmount), withdrawalAddress)
          .then((response) => {
            if (response.success) {
              handleResponse(t('pages.account.withdrawal.content.success') + '');
              setWithdrawalAddress('');
              setWithdrawalAmount(undefined);
              fetchWallets();
            } else {
              handleResponse(response, true);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data, true);
          })
          .finally(() => {
            sentWithdrawRequestRef.current = false;
            setIsLoading(false);
          });
      }
    });
  }, [isDataValid, withdrawalAmount, withdrawalAddress, selectedCurrency, fetchWallets, wallets, handleResponse, t]);

  useEffect(() => {
    setSelectedCurrency(availableOptions[0]);
  }, []);

  useEffect(() => {
    let withdrawalMaxAmountValue = 0;
    if (selectedCurrency && wallets.length > 0) {
      const foundWallet = wallets.find(({ currency }) => mapCurrency(currency) === selectedCurrency);
      if (foundWallet) {
        withdrawalMaxAmountValue = new BigNumber(foundWallet.balance).decimalPlaces(2, 1).toNumber();
      }
    }
    setWithdrawalAmount(undefined);
    setWithdrawalMaxAmount(withdrawalMaxAmountValue);
  }, [selectedCurrency, wallets]);

  useEffect(() => {
    if (!withdrawalAmount || !tokenBank || !selectedCurrency) {
      setConvertedAmount(0);
      return;
    }

    if (selectedCurrency === ECurrency.AFT) {
      setConvertedAmount(Number(withdrawalAmount) * tokenBank.aufPrice);
    } else if (selectedCurrency === ECurrency.GAT) {
      setConvertedAmount(Number(withdrawalAmount) * tokenBank.gatPrice);
    }
  }, [withdrawalAmount, tokenBank, selectedCurrency]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.withdrawal.title')}</title>
      </Helmet>
      <div id="withdrawal">
        <h2 className="heading mb-2 tx-uppercase">{t('pages.account.withdrawal.content.heading')}</h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.withdrawal.content.subheading')}</h6>
        <div className="switcher-holder">
          <RadioSwitcher<ECurrency>
            id="wallet-currency"
            options={options}
            handleValueChange={handleCurrencyChange}
            selectedValue={selectedCurrency}
          />
        </div>
        <div className="withdrawal-block mt-6">
          <div>
            <h5 className="tx-normal">
              {t('pages.account.withdrawal.content.withdraw-label', { currency: selectedCurrency })}
            </h5>
            <div className="d-flex mt-3 mb-1 align-items-center gap-3">
              <Form.Control
                type="text"
                size="lg"
                placeholder="0.00"
                value={withdrawalAmount !== undefined ? withdrawalAmount : ''}
                id="withdrawalAmount"
                onChange={(event) => {
                  let value = event.target.value.replace(/[^\d.]/g, '');
                  if (value.indexOf('.') !== -1) {
                    let count = 0;
                    for (let i = 0; i < value.length; i++) {
                      if (value[i] === '.') {
                        count++;
                      }
                    }
                    if (count > 1) {
                      value = value.substring(0, value.indexOf('.') + 1) + value.substring(value.indexOf('.') + 1).replace(/\./g, '');
                    } else if (count === 1) {
                      value = value.substring(0, value.indexOf('.') + 1) + value.substring(value.indexOf('.') + 1, value.indexOf('.') + 3)
                    }
                  }
                  setWithdrawalAmount(value ? value : undefined)
                }}
                disabled={isLoading}
              />
              {selectedCurrency !== ECurrency.USDT && convertedAmount > 0 && (
                <FormattedNumber
                  value={convertedAmount}
                  suffix={'≈ '}
                  postfix={ECurrency.USDT}
                  decimals={2}
                  floor
                  className="converted-value tx-italic tx-gray-500"
                />
              )}
            </div>
            <div className="ml-3 tx-gray-600">
              {t('common.available')}:
              <FormattedNumber
                value={withdrawalMaxAmount}
                postfix={selectedCurrency}
                decimals={2}
                floor
                className="text-decoration-underline ms-1 cur-pointer"
                onClick={() => setWithdrawalAmount(withdrawalMaxAmount.toString())}
              />
            </div>
          </div>
          <div className="mt-5">
            <h5 className="tx-normal">{t('pages.account.withdrawal.content.wallet-label')}</h5>
            <div className="d-flex mt-3 mb-1 align-items-center gap-3">
              <Form.Control
                type="text"
                size="lg"
                className={`form-control ${isAddressValid ? 'is-valid' : withdrawalAddress && 'is-invalid'}`}
                placeholder={t('pages.account.withdrawal.content.address') + ''}
                value={withdrawalAddress}
                id="trc20-address"
                onChange={(event) => setWithdrawalAddress(event.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="mt-5">
            <button className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0" onClick={handleWithdraw}>
              <IconWithLoading icon={faMoneyFromBracket} isLoading={isLoading} className="me-3" />
              {t('pages.account.withdrawal.content.withdraw')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
});
