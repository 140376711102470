import { faTableList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Table } from '@~components/table/Table';
import { ECurrency } from '@~types/enums';
import type { IHeader, IReferralInfo } from '@~types/types';
import { tokenBankAtom } from '@~store/account.store';

const tableHeaders: IHeader[] = [
  { key: 'line', translateKey: 'pages.account.partners.content.affiliate-program.line' },
  { key: 'partners', translateKey: 'pages.account.partners.content.affiliate-program.partners' },
  { key: 'earned', translateKey: 'pages.account.partners.content.affiliate-program.earned' },
  { key: 'missed', translateKey: 'pages.account.partners.content.affiliate-program.missed' },
  // {
  //   key: 'aft-turnover',
  //   translateKey: 'pages.account.partners.content.affiliate-program.currency-turnover',
  //   options: { currency: ECurrency.AFT },
  // },
  // {
  //   key: 'gat-turnover',
  //   translateKey: 'pages.account.partners.content.affiliate-program.currency-turnover',
  //   options: { currency: ECurrency.GAT },
  // },
  {
    key: 'usdt-turnover',
    translateKey: 'pages.account.partners.content.affiliate-program.currency-turnover',
    options: { currency: ECurrency.USDT },
  },
  // { key: 'total-turnover', translateKey: 'pages.account.partners.content.affiliate-program.total-turnover' },
];

interface IAffiliateResultsTableModalProps {
  referralInfo: IReferralInfo | undefined;
}

export const AffiliateResultsTableModal = memo(({ referralInfo }: IAffiliateResultsTableModalProps) => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);

  const [openModal, setOpenModal] = useState(false);

  const tableRows = useMemo(() => {
    if (referralInfo) {
      return referralInfo.lines.map((line) => {
        const lineAftEarnedInUsdt = tokenBank ? line.earn * tokenBank.aufPrice : 0;
        const lineAftMissedInUsdt = tokenBank ? line.lose * tokenBank.aufPrice : 0;
        const lineAftTurnoverInUsdt = tokenBank ? line.aufTurnover * tokenBank.aufPrice : 0;
        const lineGatTurnoverInUsdt = tokenBank ? line.gatTurnover * tokenBank.gatPrice : 0;
        const lineTotalTurnoverInUsdt = lineAftTurnoverInUsdt + lineGatTurnoverInUsdt + line.usdTurnover;

        return {
          id: line.line + '',
          cells: [
            <div key="line">
              {line.line} (
              <FormattedNumber key="percents" value={line.rewardPercents} postfix="%" className="tx-bold" />)
            </div>,
            <div key="partners-count">{line.referralsCount}</div>,
            <div key="earned">
              <FormattedNumber className="d-block" value={line.earn} postfix={ECurrency.USDT} decimals={2} floor />
              {lineAftEarnedInUsdt > 0 && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={lineAftEarnedInUsdt}
                  suffix="≈$"
                  floor
                />
              )}
            </div>,
            <div key="missed">
              <FormattedNumber className="d-block" value={line.lose} postfix={ECurrency.USDT} decimals={2} floor />
              {lineAftMissedInUsdt > 0 && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={lineAftMissedInUsdt}
                  suffix="≈$"
                  floor
                />
              )}
            </div>,
            // <div key="aft-turnover">
            //   <FormattedNumber
            //     className="d-block"
            //     value={line.aufTurnover}
            //     postfix={ECurrency.AFT}
            //     decimals={2}
            //     floor
            //   />
            //   {lineAftTurnoverInUsdt > 0 && (
            //     <FormattedNumber
            //       className="d-block tx-normal tx-14 tx-italic"
            //       value={lineAftTurnoverInUsdt}
            //       suffix="≈$"
            //       floor
            //     />
            //   )}
            // </div>,
            // <div key="gat-turnover">
            //   <FormattedNumber
            //     className="d-block"
            //     value={line.gatTurnover}
            //     postfix={ECurrency.GAT}
            //     decimals={2}
            //     floor
            //   />
            //   {lineGatTurnoverInUsdt > 0 && (
            //     <FormattedNumber
            //       className="d-block tx-normal tx-14 tx-italic"
            //       value={lineGatTurnoverInUsdt}
            //       suffix="≈$"
            //       floor
            //     />
            //   )}
            // </div>,
            // <FormattedNumber
            //   key="usdt-turnover"
            //   className="d-block"
            //   value={line.usdTurnover}
            //   postfix={ECurrency.USDT}
            //   decimals={2}
            //   floor
            // />,
            <FormattedNumber
              key="total-turnover"
              className="d-block"
              value={lineTotalTurnoverInUsdt}
              postfix={ECurrency.USDT}
              decimals={2}
              floor
            />,
          ],
        };
      });
    }
    return [];
  }, [referralInfo, tokenBank]);

  return (
    <>
      <button className="btn btn-secondary p-0" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon
          icon={faTableList}
          size="2x"
          title={t('pages.account.partners.content.affiliate-program.results-icon') + ''}
        />
      </button>

      <ModalAlert
        key="modal-for-affiliate-program"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.partners.content.affiliate-program.results-title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        size="xl"
        noOk
        enableEscape
        content={
          <div className="mb-3">
            <Table
              id="affiliate-results-table"
              headers={tableHeaders}
              rows={tableRows}
              rowsPerPage={tableRows.length}
              dataLoaded={!!referralInfo}
            />
          </div>
        }
      />
    </>
  );
});
