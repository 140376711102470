import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDown, faArrowsRotate, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { Preloader } from '@~components/preloader/Preloader';
import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { QrCode } from '@~components/qrcode/QrCode';
import { useBreakpoint } from '@~hooks/useBreakpoint';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { createDepositOption, getDepositOptions } from '@~network/account';
import { isAdminAtom } from '@~store/account.store';
import { EBreakpoint } from '@~styles/breakpoints';
import { ECryptoTicker } from '@~types/enums';
import type { IDepositOption } from '@~types/types';

interface ICryptoData {
  id: ECryptoTicker;
  label: string;
  network: string;
}

const cryptoTicketMap: Record<ECryptoTicker, ICryptoData> = {
  [ECryptoTicker.BTC]: { id: ECryptoTicker.BTC, label: 'BTC', network: 'bitcoin' },
  [ECryptoTicker.ETH]: { id: ECryptoTicker.ETH, label: 'ETH', network: 'ethereum (erc-20)' },
  [ECryptoTicker.ERC_USDT]: { id: ECryptoTicker.ERC_USDT, label: 'USDT (ERC-20)', network: 'ethereum (erc-20)' },
  [ECryptoTicker.TRC_USDT]: { id: ECryptoTicker.TRC_USDT, label: 'USDT (TRC-20)', network: 'tron (trc-20)' },
};

const allDepositWallets = [ECryptoTicker.BTC, ECryptoTicker.ETH, ECryptoTicker.ERC_USDT, ECryptoTicker.TRC_USDT];

const supportTeam = [
  { name: 'Support Aurous (Pavel)', link: 'https://t.me/Aurous_Pavel' },
  { name: 'Support Aurous (Yenya)', link: 'https://t.me/Aurous_Yenya' },
  { name: 'Support Aurous (Alexandr)', link: 'https://t.me/Aurous_Alexandr' },
  { name: 'Support Aurous (Nikita)', link: 'https://t.me/Aurous_Nikita' },
  { name: 'Support Aurous (Andrey)', link: 'https://t.me/Aurous_Andrey' },
  { name: 'Support Aurous (Tracy)', link: 'https://t.me/Aurous_Tracy' },
];

const NOT_AVAILABLE = false;

export const DepositPage = memo(() => {
  const { t } = useTranslation();

  const [isAdmin] = useAtom(isAdminAtom);

  const [selectedWalletId, setSelectedWalletId] = useState<ECryptoTicker | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [depositOptions, setDepositOptions] = useState<IDepositOption[]>([]);

  const requestSentRef = useRef(false);

  const breakpoint = useBreakpoint();
  const handleResponse = useResponseHandler();

  const depositWallets = useMemo(() => {
    if (isAdmin) {
      return allDepositWallets;
    }
    return [ECryptoTicker.TRC_USDT];
  }, [isAdmin]);

  const options: ISwitcherOption<ECryptoTicker>[] = useMemo(
    () => depositWallets.map((walletId) => ({ value: walletId, label: cryptoTicketMap[walletId].label })),
    [depositWallets]
  );

  const fetchOptions = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setIsLoading(true);
    setIsError(false);

    getDepositOptions()
      .then((response) => {
        if (response[0].address) {
          setDepositOptions(response);
          setIsInit(true);
        } else {
          handleResponse(response[0].address);
          setIsError(true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsError(true);
      })
      .finally(() => {
        requestSentRef.current = false;
        setIsLoading(false);
      });
  }, [handleResponse]);

  const createOption = useCallback(() => {
    if (!selectedWalletId || requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setIsLoading(true);
    setIsError(false);

    // createDepositOption(selectedWalletId)
    //   .then((response) => {
    //     if (response.success && response.data) {
    //       const depositOption = response.data;
    //       setDepositOptions((prevState) => [...prevState, depositOption]);
    //       toast.success(
    //         t('pages.account.deposit.content.address-generated', {
    //           currency: cryptoTicketMap[selectedWalletId].label,
    //         })
    //       );
    //     } else {
    //       handleResponse(response);
    //       setIsError(true);
    //     }
    //   })
    //   .catch((response) => {
    //     handleResponse(response.response.data);
    //     setIsError(true);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     requestSentRef.current = false;
    //   });
  }, [selectedWalletId]);

  useEffect(() => {
    setSelectedWalletId(depositWallets[0]);
  }, [depositWallets]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleValueChange = useCallback((value: ECryptoTicker) => {
    setSelectedWalletId(value);
  }, []);

  const currentOption = depositOptions.find((option) => option.cryptocurrency === 'TRC_USDT');

  const qrCodeSize = breakpoint < EBreakpoint.XXXL ? 200 : 270;

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.deposit.title')}</title>
      </Helmet>
      <div id="dashboard">
        <h2 className="heading mb-2 tx-uppercase">{t('pages.account.deposit.content.heading')}</h2>
        {/*<h6 className="subheading tx-gray-600">{t('pages.account.deposit.content.subheading')}</h6>*/}
        <div className="switcher-holder mt-6">
          {breakpoint < EBreakpoint.SM && (
            <Dropdown id="account-mobile-navigation" align="end">
              <Dropdown.Toggle variant="transparent" className="card-block p-3 account-menu-select notranslate">
                {selectedWalletId && cryptoTicketMap[selectedWalletId].label}
                <FontAwesomeIcon icon={faAngleDown} className="caret" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {options.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    className={classNames('d-inline-flex gap-4', { active: option.value === selectedWalletId })}
                    onClick={() => setSelectedWalletId(option.value)}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {breakpoint >= EBreakpoint.SM && (
            <RadioSwitcher<ECryptoTicker>
              id="wallet-currency"
              options={options}
              handleValueChange={handleValueChange}
              selectedValue={selectedWalletId}
            />
          )}
        </div>
        {NOT_AVAILABLE && (
          <div className="notice">
            <h4 className="subheading mt-6 tx-gray-300">{t('common.under-maintenance')}</h4>
            <h5 className="subheading mb-6 tx-gray-300">{t('common.coming-soon')}</h5>
            <div className="tx-gray-200 tx-18">
              <p className="tx-bold mb-3">
                <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary me-2" />
                {t('pages.account.deposit.content.alternative.important')}
                <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary ms-2" />
              </p>
              <p className="mb-3">{t('pages.account.deposit.content.alternative.text1')}</p>
              <p className="mb-3">{t('pages.account.deposit.content.alternative.text2')}</p>
              <ul className="list-unstyled">
                {supportTeam.map((member) => (
                  <li key={member.name}>
                    <a href={member.link} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faTelegramPlane} className="me-2 tx-white" />
                      {member.name}
                    </a>
                  </li>
                ))}
              </ul>
              <p className="mb-3">{t('pages.account.deposit.content.alternative.text3')}</p>
            </div>
          </div>
        )}
        {selectedWalletId && !NOT_AVAILABLE && (
          <div className="deposit-wallet-data mt-6">
            {!isError && currentOption && (
              <div className="deposit-wallet-address d-flex flex-column flex-xxl-row align-items-xxl-center justify-content-start">
                <div className="qrcode">
                  <QrCode options={{ data: currentOption.address, width: qrCodeSize, height: qrCodeSize }} />
                </div>
                <div className="card-block text-center text-lg-start flex-1">
                  <h5 className="card-title tx-18">
                    {t('pages.account.deposit.content.personal-address', {
                      currency: cryptoTicketMap[selectedWalletId].label,
                    })}
                  </h5>
                  <p className="card-text mb-0 tx-bold tx-20">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                    {t('pages.account.deposit.content.network')}{' '}
                    <span className="tx-uppercase">{cryptoTicketMap[selectedWalletId].network}</span>
                  </p>
                  {/*<p className="card-text mb-0 tx-bold tx-20">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                    {t('pages.account.deposit.content.currency-note', {
                      currency: cryptoTicketMap[selectedWalletId].label,
                    })}
                  </p>*/}
                  <CopyButton
                    text={currentOption.address}
                    element={currentOption.address}
                    className="btn btn-link tx-white px-4 mt-3 word-break"
                    iconClass="tx-white tx-14 ml-2"
                  />
                </div>
              </div>
            )}
            {!isError && isInit && !currentOption && (
              <div className="card-block">
                <h5 className="card-title">
                  {t('pages.account.deposit.content.no-address', {
                    currency: cryptoTicketMap[selectedWalletId].label,
                  })}
                </h5>
                <button className="notranslate btn btn-dark mb-4 mb-md-0" onClick={createOption}>
                  <IconWithLoading icon={faLayerPlus} className="me-2" isLoading={isLoading} />
                  {t('pages.account.deposit.content.generate-address', {
                    currency: cryptoTicketMap[selectedWalletId].label,
                  })}
                </button>
              </div>
            )}
            {!isError && !isInit && (
              <div className="card-block ht-150 d-flex justify-content-center align-items-center">
                <Preloader inline />
              </div>
            )}
            {isError && (
              <div className="card-block ht-150 d-flex flex-column justify-content-center align-items-center">
                <p className="tx-bold mb-3">{t('error.loading')}</p>
                {/* <button className="btn btn-dark mb-4 mb-md-0" onClick={fetchOptions}>
                  <IconWithLoading icon={faArrowsRotate} className="me-2" isLoading={isLoading} />
                  {t('common.action.refresh')}
                </button> */}

                <a
                  className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0"
                  href={supportTeam[0].link}
                  target="_blank"
                  rel="depositTg"
                  title="title"
                >
                  {supportTeam[0].name}
                  {/* <FontAwesomeIcon icon={social.icon as IconDefinition} /> */}
                </a>

              </div>
            )}
            {/*<p className="mt-6 mb-3">{t('pages.account.deposit.content.alternative.deposit-without-commission')}:</p>
            <ul className="list-unstyled">
              {supportTeam.map((member) => (
                <li key={member.name}>
                  <a href={member.link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTelegramPlane} className="me-2 tx-white" />
                    {member.name}
                  </a>
                </li>
              ))}
            </ul>*/}
          </div>
        )}
      </div>
    </>
  );
});
