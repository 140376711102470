import { ELanguage } from '@~types/enums';
import { INews } from '@~types/types';

import cipCilGildingFactory from '@~assets/images/news/cip-cil-gilding-factory.jpeg';
import goldFromOre from '@~assets/images/news/gold-from-ore.jpeg';
import sudanFlag from '@~assets/images/news/flag-of-Sudan.jpeg';

export const newsList: INews[] = [
  {
    id: '1-en',
    lang: ELanguage.EN,
    image: cipCilGildingFactory,
    slug: 'cip-cil-gilding-factory',
    title: 'CIP / CIL gilding factory',
    shortDescription: (
      <>
        <p>The CIP/CIL gold treatment plant is a cyanide pulp filtration-free process.</p>
      </>
    ),
    description: (
      <>
        <p>
          The CIP/CIL gold treatment plant is a cyanide pulp filtration-free process that uses activated carbon to
          directly absorb and recover gold from cyanide pulp, including seven steps: leach pulp preparation, cyanide
          leaching, absorbed carbon capture, desorption of gold absorbed coal. , preparation of gold mud by current,
          processing of coal to extract gold and processing of leaching pulp.
        </p>
      </>
    ),
    date: '2023-02-02 08:11:00',
  },
  {
    id: '1-ru',
    lang: ELanguage.RU,
    image: cipCilGildingFactory,
    slug: 'cip-cil-gilding-factory',
    title: 'Завод по золочению CIP/CIL',
    shortDescription: (
      <>
        <p>Установка для обработки золота CIP/CIL представляет собой процесс без фильтрации цианидной пульпы.</p>
      </>
    ),
    description: (
      <>
        <p>
          Установка обработки золота CIP/CIL - процесс без фильтрации цианидной пульпы, использующий активированный
          уголь для непосредственного поглощения и извлечения золота из цианидной пульпы, включающий семь этапов:
          приготовление выщелачивающей пульпы, выщелачивание цианидной пульпы, улавливание поглощенного углерода,
          десорбция поглощенного золота, приготовление золотого шлама по току, переработка угля для извлечения золота и
          переработка выщелачивающей пульпы.
        </p>
      </>
    ),
    date: '2023-02-02 08:11:00',
  },
  {
    id: '2-en',
    lang: ELanguage.EN,
    image: goldFromOre,
    slug: 'obtaining-gold-from-ore',
    title: 'The process of obtaining gold from ore',
    shortDescription: (
      <>
        <p>
          Currently, gold recovery technologies have been supplemented by cyanide and bacterial heap leaching,
          underground chloride leaching, and bacterial vat leaching.
        </p>
      </>
    ),
    description: (
      <>
        <p>
          Currently, gold recovery technologies have been supplemented by cyanide and bacterial heap leaching,
          underground chloride leaching, and bacterial vat leaching. Due to this, you can extract gold from almost any
          ore. Modern equipment makes it possible to conduct processes with greater efficiency and productivity.
          However, the ability of specialists of the last century to implement complex processes with the simplest
          technical means in wooden resin barrels, in our opinion, is surprising and admirable. To anyone interested in
          the history of gold mining, we recommend the book by Yu.E. Rukosuev "Gold and Platinum of the Urals: the
          history of mining in the late 19th - early 20th centuries." Yekaterinburg - 2004, 196 p. Below is a chapter
          from this book.
        </p>
        <p>
          In 1862, a Swedish subject, Nordstrom, conducted experiments in the chemical laboratory of Yekaterinburg to
          extract gold from the dumping sands of the Berezovsky fields using chlorine. For processing, he required sands
          that would contain at least 48 shares (1.32 g / t) of gold in the form of dust in 100 pounds. It was
          impossible to fulfill these conditions at the Berezovsky mines, since sands with a content of 20 and even 16
          shares / 1 / (0.55-0.44 g / t) were washed there at that time. The experiment did not give any useful results.
        </p>
        <p>
          In 1886, engineer E.P. Zelenkov leased all his dumps from the owner of the Uspensky mine Novikov in the
          Troitsky district of the Orenburg province and built the first plant in the Urals for the chemical extraction
          of gold by chlorination according to the Platner method. This method included the following operations: ore
          roasting, gold chlorination in special vats, chlorine gold leaching and gold precipitation from solutions.
        </p>
        <p>
          The first good results gave impetus to the construction of chlorination plants in the Urals in all areas where
          there were more dumps, mainly mined underground. All these plants were quite simple in design, they were all
          divided into three departments: enrichment, roasting and chlorination. Horizontal millstones were installed in
          the beneficiation department, which served to grind the ephels, which were packed into lumps. After washing,
          the enriched ephels, which contained mainly pyrites, entered the second department - the burning one. Roasting
          was carried out in reverberatory furnaces. His result was the conversion of sulfur compounds into sulfate.
          Throughout this process pyrites were mixed with iron combs to increase the speed and uniformity of firing, as
          well as to protect them from sintering. After cooling, the ore entered the third section - chlorination, where
          chlorination, settling and sedimentation vats made of wood were installed.
        </p>
        <p>
          Chlorinated wooden vats were tarred inside and had a double bottom with holes. A layer of pure quartz was
          poured on it or asbestos cardboard was laid, which played the role of a filter. From below, two taps were
          connected to the vats: one served to release water, the other to let in chlorine. The second tap was connected
          by a lead pipe to an apparatus in which chlorine was obtained from a mixture of manganese peroxide, common
          salt (sodium chloride) and sulfuric acid. Up to 160 pounds of burnt pyrites were poured into the vats, covered
          with a lid and covered tightly, after which the chlorine supply valve was opened.
        </p>
        <p>
          Chlorination continued up to 6 days. Its duration depended on the firing temperature and the quality of
          pyrites. The time when the contents of the vat are chlorinated, i.e. when chlorine came to the surface of the
          ore, it was determined using ammonia, which was brought to a special hole in the lid of the vat. If white
          smoke began to rise, chlorination was considered complete. Then the valve of the chlorine pipeline was closed
          and the hole was covered up. 12 hours after the chlorination ceased, water was pumped into the vat to dissolve
          the resulting chloride gold; the resulting solution was discharged through rubber pipes into a settling tank.
          The ore that remained in the vat was unloaded and taken to the dump. After a few days, the resulting
          precipitate was transferred to a settling tank, where gold was isolated using ferrous sulfate (iron sulfate),
          collected, dried and melted in special furnaces.
        </p>
        <p>
          18 chlorination tanks, 12 settling tanks and 8 settling tanks were installed at the plant of E.P. Zelenkov.
          For 5 years of work, from 1886 to 1890, 17 pounds, 2 pounds 83 spools 30 shares of gold were obtained. It was
          obtained from dumps that were previously considered useless.
        </p>
        <p>
          In the 90s. 19th century in the Urals, another method of chemical extraction of gold began to be used -
          cyanization (cyanidation), which has become quite widespread. This was largely facilitated by the government
          decree of August 23, 1891, according to which the gold miner, who built a factory for the chemical extraction
          of gold and began to process old dumps, was exempted from paying land tax.
        </p>
        <p>
          The cyanization method was invented in 1843 by engineer P.R.Bagration, who revealed the ability of gold to
          dissolve in aqueous solutions of alkaline cyanides. Gold contained in epheles by interaction with potassium
          cyanide was converted to soluble double gold and potassium cyanide salt, and zinc precipitated from this
          solution. The entire process of cyanization was divided into three parts: leaching, precipitation and gold
          stripping.
        </p>
      </>
    ),
    date: '2023-02-23 13:42:00',
  },
  {
    id: '2-ru',
    lang: ELanguage.RU,
    image: goldFromOre,
    slug: 'obtaining-gold-from-ore',
    title: 'Процесс получения золота из руды',
    shortDescription: (
      <>
        <p>
          В настоящее время технологии извлечения золота дополняются цианидным и бактериальным кучным выщелачиванием,
          подземным хлоридным выщелачиванием и бактериальным кубовым выщелачиванием.
        </p>
      </>
    ),
    description: (
      <>
        <p>
          В настоящее время технологии извлечения золота дополняются цианидным и бактериальным кучным выщелачиванием,
          подземным хлоридным выщелачиванием и бактериальным кубовым выщелачиванием. За счет этого можно добывать золото
          практически из любой руды. Современное оборудование позволяет проводить процессы с большей эффективностью и
          производительностью. Однако способность специалистов прошлого века реализовывать сложные процессы простейшими
          техническими средствами в деревянных полимерных бочках, на наш взгляд, вызывает удивление и восхищение. Всем,
          кто интересуется историей золотодобычи, мы рекомендуем книгу Ю.Е. Рукосуев «Золото и платина Урала: история
          горного дела в конце XIX - начале XX веков». Екатеринбург - 2004, 196 с. Ниже приведена глава из этой книги.
        </p>
        <p>
          В 1862 г. шведский подданный Нордстрем проводил в химической лаборатории Екатеринбурга опыты по извлечению
          золота из откидных песков Березовских промыслов с помощью хлора. Для обработки он требовал пески, которые
          содержали бы в 100 пудах не менее 48 долей (ползолотника) золота в виде пыли. Выполнить эти условия на
          Березовских приисках было невозможно, т.к. там в это время промывались пески с содержанием 20 и даже 16
          долей39. Эксперимент не дал каких-либо полезных результатов.
        </p>
        <p>
          В 1886 г. инженер Е.П. Зеленков взял в аренду у владельца Успенского прииска Новикова в Троицком уезде
          Оренбургской губернии все его отвалы и построил первый на Урале завод химического извлечения золота
          хлоринацией по методу Платнера. Этот метод включал в себя следующие операции: обжиг руды, хлорирование золота
          в специальных чанах, выщелачивание хлорного золота и осаждение золота из растворов.
        </p>
        <p>
          Первые хорошие результаты дали толчок к строительству на Урале хлоринационных заводов во всех районах, где
          имелось большое количество отвалов, добытых главным образом подземным способом. Все эти заводы были довольно
          просты по устройству, все они разделялись на три отделения: обогатительное, обжигательное и хлоринационное. В
          обогатительном отделении были установлены горизонтальные жернова, служащие для растирания эфелей, слежавшихся
          в комки. После промывки обогащенные эфеля, содержавшие главным образом колчеданы, поступали во второе
          отделение — обжигательное. Обжиг производился в отражательных печах. Его результатом был перевод сернистых
          соединений в сернокислые. В течение всего этого процесса колчеданы перемешивали железными гребенками для
          увеличения быстроты и равномерности обжига, а также для предохранения их от спекания. После охлаждения руда
          поступала в третье отделение — хлоринационное, где устанавливались хлоринационные, отстосчные и осадочные
          чаны, изготовленные из дерева.
        </p>
        <p>
          Хлоринационные деревянные чаны были просмолены внутри и имели двойное дно с отверстиями. На него насыпался
          слой чистого кварца или укладывался асбестовый картон, игравшие роль фильтра. Снизу к чанам подводились два
          крана: один служил для выпуска воды, другой — для впуска хлора. Второй кран соединялся свинцовой трубой с
          аппаратом, в котором получали хлор из смеси перекиси марганца, поваренной соли (хлорида натрия) и серной
          кислоты. В чаны засыпали до 160 пудов (2620,8 кг) обожженных колчеданов, закрывали крышкой и плотно
          замазывали, после чего открывали хлоропроводный кран.
        </p>
        <p>
          Хлорирование продолжалось до 6 дней. Его продолжительность зависела от температуры обжига и качества
          колчеданов. Время, когда содержимое чана прохлорируется, т.е. когда хлор выйдет на поверхность руды,
          определялось с помощью аммиака, который подносили к специальному отверстию в крышке чана, если начинал
          подниматься белый дым, хлоринация считалась законченной. Тогда кран хлоропровода закрывался и отверстие
          замазывалось. Через 12 часов после прекращения хлорирования в чан накачивали воду для растворения
          образовавшегося хлорного золота, полученный раствор по каучуковым трубам отводили в отстоечный чан. Руду,
          которая оставалась в чане, выгружали и вывозили в отвал. Полученный осадок через несколько дней переводили в
          осадительный чан, где золото выделялось с помощью железного купороса (сульфата железа), собиралось, сушилось и
          сплавлялось в особых печах.
        </p>
        <p>
          На заводе Е.П. Зеленкова было установлено 18 хлоринационных чанов, 12 отстоечных и 8 осадительных. За 5 лет
          работы, с 1886 по 1890 г., было получено 17 пудов, 2 фунта 83 золотника 30 долей золота. Получено оно было из
          отвалов, которые раньше считались бесполезными.
        </p>
        <p>
          В 90-х гг. XIX в. на Урале стал применяться другой способ химического извлечения золота — цианизация
          (цианирования), получивший довольно широкое распространение. Этому в значительной степени способствовало
          постановление правительства от 23 августа 1891 г., согласно которому золотопромышленник, построивший фабрику
          химического извлечения золота и начинающий перерабатывать старые отвалы, освобождался от уплаты поземельной
          подати.
        </p>
        <p>
          Метод цианизации был изобретен в 1843 г. инженером П.Р. Багратионом, который выявил способность золота
          растворяться в водных растворах щелочных цианидов. Золото, содержавшееся в эфелях, взаимодействием с цианистым
          калием, переводилось в растворимую двойную цианистую соль золота и калия, и из этого раствора осаждалось
          цинком. Весь процесс цианизации делился на три части: выщелачивание, осаждение и сполоск золота.
        </p>
      </>
    ),
    date: '2023-02-23 13:42:00',
  },
  {
    id: '3-en',
    lang: ELanguage.EN,
    image: sudanFlag,
    slug: 'facts-about-sudan',
    title: 'Interesting facts about Sudan',
    shortDescription: (
      <>
        <p>Sudan is a country with a rich but difficult history...</p>
      </>
    ),
    description: (
      <>
        <p>
          Sudan is a country with a rich but difficult history. The irresponsible actions of the colonialists led to the
          fact that Sudan turned into a continuously seething hotbed of rebels and rebels of all stripes for many
          decades, and only recently the situation here has at least relatively calmed down. Tourists in Sudan have
          nothing to do, although there are brave travelers who are not afraid to visit this little-studied corner of
          the Earth.
        </p>
        <p>Interesting facts about Sudan.</p>
        <p>
          Western colonialists established the borders of Sudan, completely ignoring the interfaith and economic ties
          that existed in the region. As a result, the country has been in a state of civil war for almost its entire
          history. In 2011, the feuds culminated in the secession of South Sudan, which became an independent state in a
          subsequent referendum.
        </p>
        <p>
          On the border of Egypt and Sudan, there is an area of more than 2 thousand kilometers, which belongs to no
          one. Initially, it was the territory of Sudan, but then the British government changed the administrative
          border between the countries, giving the area into the possession of Egypt. Later, Cairo demanded to return to
          the old borders and refused the territory granted to it, while Sudan preferred to leave everything as it was
          and did not return the land to itself.
        </p>
        <p>
          Tourists with an Israeli visa in their passport are denied entry to Sudan, as well as to many other Muslim
          countries. The ban applies even to those foreigners who have a Sudanese visa (see interesting facts about
          Israel).
        </p>
        <p>
          In Sudan, marriages with the dead are allowed - the so-called "ghost marriages" are often entered into by
          pregnant women in order to legitimize the children of their dead loved ones. Forests cover only 10% of the
          territory of Sudan (see interesting facts about forests).
        </p>
        <p>
          Most of the roads in Sudan are unpaved and therefore completely unsuitable for driving during the rainy
          season. Cargo transportation in Sudan is poorly developed - either porters or pack animals are involved in the
          delivery of goods. The name of the capital of Sudan, the city of Khartoum, is translated from Arabic as
          "elephant's trunk". The fact is that the city was founded on Cape Ras al-Khartoum (“Tip of an elephant's
          trunk”). The largest and most popular museum in the country is the National Museum of Sudan, located in the
          capital. Part of the exposition, located in the gardens around the museum building, are two religious
          buildings of the ancient Egyptians: Buhen Temple and Semna Temple. They were moved to Khartoum due to the
          construction of a dam and the flooding of the area where the temples were originally built.
        </p>
        <p>
          In the capital, near the confluence of the White and Blue Nile, there is an unusual amusement park, which,
          moreover, offers a beautiful view of the river (see interesting facts about the Nile).
        </p>
        <p>Sudan is the world's largest importer of gum arabic, which is used to make medicines and paints.</p>
        <p>
          Contrary to popular belief, most of the pyramids are located in Sudan, not Egypt. According to archaeologists,
          in the expanses of the former Nubia there are more than 220 pyramids with a height of 20 to 30 meters.
        </p>
        <p>
          Tourists are not advised to travel outside of Khartoum, as cases of kidnapping of foreigners are not uncommon
          in the country. The basis of the national cuisine of Sudan are beans. In Sudan, it is not customary to leave
          tips for waiters, maids and other service personnel.
        </p>
        <p>
          In Sudan, the sale and consumption of alcohol is prohibited, as the country lives according to the laws of
          Islam.
        </p>
        <p>
          In Sudan, you even need to wash your face with boiled water, as tap water is teeming with germs. Swimming in
          local rivers and other fresh water bodies is also strongly discouraged (see interesting facts about the rivers
          of Africa).
        </p>
        <p>
          On the territory of Sudan there is a large-scale archaeological complex "The Great Wall" - these are the ruins
          of ancient monuments erected around the 3rd century BC. Scientists have not yet come to a final conclusion
          about the purpose of these buildings.
        </p>
        <p>
          Divers from all over the world come to Sudan for the opportunity to dive in the untouched part of the Red Sea
          (see interesting facts about the Red Sea).
        </p>
      </>
    ),
    date: '2023-03-12 11:37:00',
  },
  {
    id: '3-ru',
    lang: ELanguage.RU,
    image: sudanFlag,
    slug: 'facts-about-sudan',
    title: 'Интересные факты о Судане',
    shortDescription: (
      <>
        <p>Судан - страна с богатой, но трудной историей...</p>
      </>
    ),
    description: (
      <>
        <p>
          Судан - страна с богатой, но трудной историей. Безответственные действия колонизаторов привели к тому, что
          Судан на многие десятилетия превратился в непрерывно бурлящий очаг повстанцев и повстанцев всех мастей, и лишь
          недавно ситуация здесь хотя бы относительно успокоилась. Туристам в Судане делать нечего, хотя есть смелые
          путешественники, которые не боятся посещать этот малоизученный уголок Земли.
        </p>
        <p>Интересные факты о Судане.</p>
        <p>
          Западные колонизаторы установили границы Судана, полностью проигнорировав существовавшие в регионе
          межконфессиональные и экономические связи. В результате страна почти всю свою историю находилась в состоянии
          гражданской войны. В 2011 году распри завершились отделением Южного Судана, который стал независимым
          государством на последующем референдуме.
        </p>
        <p>
          На границе Египта и Судана находится площадь более 2 тысяч километров, которая никому не принадлежит.
          Первоначально это была территория Судана, но затем британское правительство изменило административную границу
          между странами, отдав местность во владение египтянину. Позже Каир потребовал вернуться к старым границам и
          отказался от предоставленной ему территории, в то время как Судан предпочитал оставить все как есть и не
          возвращал земли себе.
        </p>
        <p>
          Туристам с израильской визой в паспорте отказывают во въезде в Судан, а также во многие другие мусульманские
          страны. Запрет распространяется даже на тех иностранцев, которые имеют суданскую визу (см. интересные факты об
          Израиле).
        </p>
        <p>
          В Судане разрешены браки с умершими - так называемые «призрачные браки» часто заключают беременные женщины,
          чтобы узаконить детей своих умерших близких. Леса покрывают лишь 10% территории Судана (см. интересные факты о
          лесах).
        </p>
        <p>
          Большинство дорог в Судане являются грунтовыми и поэтому совершенно непригодными для вождения в сезон дождей.
          Грузоперевозки в Судане развиты слабо - в доставке грузов участвуют либо носильщики, либо упаковочные
          животные. Название столицы Судана города Хартума переводится с арабского как «слоновий ствол». Дело в том, что
          город основан на мысе Рас-эль-Хартум («Наконечник ствола слона»). Крупнейшим и самым популярным музеем страны
          является Национальный музей Судана, расположенный в столице. Частью экспозиции, расположенной в садах вокруг
          здания музея, являются два религиозных здания древних египтян: храм Бухен и храм Семна. Они были перенесены в
          Хартум из-за строительства плотины и затопления местности, где первоначально строились храмы.
        </p>
        <p>
          В столице, недалеко от места слияния Белого и Голубого Нила, находится необычный парк развлечений, который,
          более того, предлагает прекрасный вид на реку (см. интересные факты о Ниле).
        </p>
        <p>
          Судан является крупнейшим в мире импортером гуммиарабика, который используется для изготовления лекарств и
          красок.
        </p>
        <p>
          Вопреки распространённому мнению, большая часть пирамид находится в Судане, а не в Египте. По данным
          археологов, на просторах бывшей Нубии находится более 220 пирамид высотой от 20 до 30 метров.
        </p>
        <p>
          Туристам не рекомендуется выезжать за пределы Хартума, так как в стране нередки случаи похищения иностранцев.
          Основу национальной кухни Судана составляют бобы. В Судане не принято оставлять чаевые для официантов,
          горничных и другого обслуживающего персонала.
        </p>
        <p>В Судане запрещена продажа и употребление алкоголя, так как страна живет по законам ислама.</p>
        <p>
          В Судане даже нужно мыть лицо кипяченой водой, так как вода из-под крана кишит микробами. Купание в местных
          реках и других пресных водоемах также категорически не рекомендуется (см. интересные факты о реках Африки).
        </p>
        <p>
          На территории Судана находится масштабный археологический комплекс «Великая стена» - это руины древних
          памятников, возведённых примерно в III веке до нашей эры. Ученые пока не пришли к окончательному выводу о
          предназначении этих зданий.
        </p>
        <p>
          Дайверы со всего мира приезжают в Судан за возможностью нырнуть в нетронутой части Красного моря (см.
          интересные факты о Красном море).
        </p>
      </>
    ),
    date: '2023-03-12 11:37:00',
  },
];
