import { ECurrency } from '@~types/enums';

const currencyMap: Record<string, ECurrency> = {
  [ECurrency.USD]: ECurrency.USDT,
  // [ECurrency.AUF]: ECurrency.AFT,
};

export function mapCurrency(currency: ECurrency) {
  return currencyMap[currency] || currency;
}
