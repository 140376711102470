import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useMemo, useRef } from 'react';
import Slider from 'react-slick';

import { Translate } from '@~components/translate/Translate';
import { NewsView } from '@~pages/news/components/news-view/NewsView';
import { selectedLanguageAtom } from '@~store/app.store';

import { newsList } from './data/news';
import { ELanguage } from '@~types/enums';

export const News = memo(() => {
  const [selectedLanguage] = useAtom(selectedLanguageAtom);

  const newsSlider = useRef<Slider>(null);

  const prev = useCallback(() => {
    if (newsSlider.current) {
      newsSlider.current.slickPrev();
    }
  }, []);

  const next = useCallback(() => {
    if (newsSlider.current) {
      newsSlider.current.slickNext();
    }
  }, []);

  const filteredNews = useMemo(() => {
    const newsLanguage = selectedLanguage === ELanguage.RU ? ELanguage.RU : ELanguage.EN;
    return newsList.filter(({ lang }) => lang === newsLanguage);
  }, [selectedLanguage]);

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="news-slider">
      <div className="container">
        <div className="d-flex justify-content-between mb-5">
          <h2 className="tx-lg-48">
            <Translate i18nKey={'pages.home.content.news.title'} />
          </h2>
          <div>
            <button className="slick-prev mr-3" onClick={prev}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="tx-50" />
            </button>
            <button className="slick-next" onClick={next}>
              <FontAwesomeIcon icon={faArrowCircleRight} className="tx-50" />
            </button>
          </div>
        </div>

        <Slider
          ref={newsSlider}
          dots={false}
          arrows={false}
          infinite={true}
          speed={300}
          slidesToShow={2}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {filteredNews.map((news) => (
            <NewsView item={news} short={true} key={news.id} />
          ))}
        </Slider>
      </div>
    </section>
  );
});
